import * as React from 'react';
import { Text } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { FootnoteReference } from '../../footnotes/components/FootnoteReference';
import { DangerousElement } from '../../DangerousElement';
import { messages } from '../../../i18n/messages';
import { useServicesContext } from '../../../context';
import { isBevAgencyVehicle } from '../../../utils';
export const Prices = ({ vehicle, price, regularPrice, showPrices, label, footnoteLabel, financeLabel, showNwsDisclaimer, availableSoon, expectedPriceLabel, footnoteSuffix, }) => {
    var _a, _b, _c, _d, _e, _f;
    const nwsDisclaimer = showNwsDisclaimer ? '<sup>*</sup>' : '';
    const carType = vehicle.type === 'N' ? 'new' : 'used';
    const priceLabel = [label, price, nwsDisclaimer].filter((item) => !!item).join(' ');
    if (!showPrices && !price) {
        return null;
    }
    const vatReclaimableLabel = vehicle.vatReclaimable
        ? useI18n(messages.prices.vatReclaimable)
        : useI18n(messages.prices.vatNotReclaimable);
    const environmentTax = (_a = vehicle.typedPrices) === null || _a === void 0 ? void 0 : _a.find((typedPrice) => {
        return typedPrice.type === 'environment_tax';
    });
    const financeRate = (_b = vehicle.typedPrices) === null || _b === void 0 ? void 0 : _b.find((typedPrice) => {
        return typedPrice.type === 'finance_rate';
    });
    const downPayment = (_c = vehicle.typedPrices) === null || _c === void 0 ? void 0 : _c.find((typedPrice) => {
        return typedPrice.type === 'down_payment';
    });
    const basePriceLabel = useI18n(messages.prices.basePriceLabel);
    const basePriceLabelDivider = '${basePrice}';
    const combinedTaxesLabel = useI18n(messages.prices.combinedTaxesLabel);
    const combinedTaxesLabelDivider = '${combinedTaxes}';
    const basePrice = (_d = vehicle.typedPrices) === null || _d === void 0 ? void 0 : _d.find((typedPrice) => {
        return typedPrice.type === 'custom_price';
    });
    const combinedTaxes = (_e = vehicle.typedPrices) === null || _e === void 0 ? void 0 : _e.find((typedPrice) => {
        return typedPrice.type === 'registration_tax';
    });
    const localeService = useServicesContext().getAdditionalService('gfa:locale-service');
    const country = localeService.countryCode;
    const norwayPrice = (country === null || country === void 0 ? void 0 : country.toLowerCase()) === 'no' && vehicle.type === 'N';
    const isBevAgency = isBevAgencyVehicle(vehicle);
    const businessModelCode = (_f = vehicle === null || vehicle === void 0 ? void 0 : vehicle.businessModel) === null || _f === void 0 ? void 0 : _f.code;
    return (React.createElement(React.Fragment, null,
        price && availableSoon && (React.createElement(Text, { variant: "copy2", weight: "normal", as: "p", spaceStackEnd: "xs", "data-testid": "expected-price-label" },
            expectedPriceLabel,
            React.createElement(FootnoteReference, { refId: "fn_expected_price" }),
            (expectedPriceLabel === null || expectedPriceLabel === void 0 ? void 0 : expectedPriceLabel.trim()) !== '' ? ':\u00A0' : '')),
        price && (React.createElement(React.Fragment, null,
            !norwayPrice && (React.createElement(React.Fragment, null,
                React.createElement(Text, { variant: "copy1", weight: "bold", spaceStackEnd: "xs", as: "span" },
                    React.createElement(DangerousElement, { html: priceLabel, as: "span" })),
                availableSoon && regularPrice && (React.createElement(Text, { variant: "copy1", spaceStackEnd: "xs", as: "span" }, ` (${useI18n(messages.prices.regularPricePrefix)}
                ${regularPrice})`)),
                React.createElement(Text, { variant: "copy1", as: "span" },
                    React.createElement(FootnoteReference, { refId: `fn_price_stockcar_${carType}` })),
                React.createElement(Text, { variant: "copy1", as: "span" },
                    React.createElement(FootnoteReference, { refId: `fn_retail_price_stockcar_${carType}` })),
                isBevAgency && (React.createElement(Text, { variant: "copy1", as: "span" },
                    React.createElement(FootnoteReference, { refId: `fn_price_${businessModelCode}` }))),
                footnoteSuffix && (React.createElement(Text, { variant: "copy1", as: "span" },
                    React.createElement(FootnoteReference, { refId: `fn_price_retail${footnoteSuffix}` }))),
                financeLabel && financeLabel !== ' ' && (React.createElement(Text, { variant: "copy1", as: "span", spaceInlineStart: "xxs" }, financeLabel)),
                React.createElement(Text, { variant: "copy1", as: "span", spaceInlineStart: "xxs" }, vatReclaimableLabel))),
            norwayPrice && (React.createElement(React.Fragment, null,
                React.createElement(Text, { variant: "copy1", weight: "bold", spaceStackEnd: "xs", as: "span" }, price),
                React.createElement("br", null),
                basePrice && (React.createElement(React.Fragment, null,
                    React.createElement(Text, { variant: "copy1", weight: "normal", as: "span" }, basePriceLabel === null || basePriceLabel === void 0 ? void 0 : basePriceLabel.replace(basePriceLabelDivider, basePrice === null || basePrice === void 0 ? void 0 : basePrice.formatted)))),
                React.createElement("br", null),
                combinedTaxes && (React.createElement(Text, { variant: "copy1", weight: "normal", as: "span" }, combinedTaxesLabel === null || combinedTaxesLabel === void 0 ? void 0 : combinedTaxesLabel.replace(combinedTaxesLabelDivider, combinedTaxes === null || combinedTaxes === void 0 ? void 0 : combinedTaxes.formatted))))),
            environmentTax && (React.createElement(React.Fragment, null,
                React.createElement(Text, { variant: "copy1", as: "span", spaceInlineStart: "xxs" }, useI18n(messages.prices.environmentTax.prefix)),
                React.createElement(Text, { variant: "copy1", weight: "bold", spaceStackEnd: "xs", as: "span" }, environmentTax.formatted),
                React.createElement(Text, { variant: "copy1", as: "span", spaceInlineStart: "xxs" }, useI18n(messages.prices.environmentTax.suffix)))))),
        financeRate && (React.createElement(React.Fragment, null,
            price && (React.createElement(Text, { variant: "copy1", as: "span" }, useI18n(messages.prices.financeRate.prefix))),
            React.createElement(Text, { variant: "copy1", weight: "bold", spaceStackEnd: "xs", as: "span" }, financeRate.formatted),
            React.createElement(Text, { variant: "copy1", as: "span", spaceInlineStart: "xxs" }, useI18n(messages.prices.financeRate.suffix)),
            downPayment && (React.createElement(React.Fragment, null,
                React.createElement(Text, { variant: "copy1", as: "span", spaceInlineStart: "xxs" }, useI18n(messages.prices.downPayment.prefix)),
                React.createElement(Text, { variant: "copy1", weight: "bold", spaceStackEnd: "xs", as: "span" }, downPayment.formatted),
                React.createElement(Text, { variant: "copy1", as: "span", spaceInlineStart: "xxs" }, useI18n(messages.prices.downPayment.suffix)))),
            !price && environmentTax && (React.createElement("div", null,
                React.createElement(Text, { variant: "copy1", as: "span" }, useI18n(messages.prices.environmentTax.prefix)),
                React.createElement(Text, { variant: "copy1", weight: "bold", spaceStackEnd: "xs", as: "span" }, environmentTax.formatted),
                React.createElement(Text, { variant: "copy1", as: "span", spaceInlineStart: "xxs" }, useI18n(messages.prices.environmentTax.suffix)))))),
        React.createElement(Text, { variant: "copy2", as: "span", spaceInlineStart: "xxs" }, footnoteLabel)));
};
