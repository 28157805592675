import {
  CodeDescription,
  EngineType,
  Envkv2024Data,
  Fuel,
  FuelTypes,
  Io,
  Picture,
  TypedPrice,
  VehicleBasic,
  Warranty,
} from '@oneaudi/vtp-shared';
import {
  LabeledTypedPrice,
  StockCar,
  StockCarConsumptionAndEmissionScope,
  StockCarEngine,
  StockCarImage,
  StockCarManufacturerAudi,
} from '../generated/graphql-operations';

export const parseImages = (images?: StockCarImage[] | null) => {
  const pictures: Picture[] = [];
  const tilesPictures: Picture[] = [];

  if (images) {
    images.forEach((image) => {
      if (image.type === 'render') {
        if (image.id.image.includes('vtp4x3')) {
          tilesPictures.push({
            key: image.url ?? 'default key',
            url: image.url,
            originalKey: image.id.image ?? 'default',
            type: image.type ?? 'render',
          });
        }
      }
      if (image.type === 'photo') {
        pictures.push({
          key: image.url ?? 'default key',
          url: image.url,
          originalKey: 'dealer-images',
          type: image.type ?? 'photo',
        });
      }
    });
  }

  return { pictures, tilesPictures };
};

export const parsePrices = (prices?: LabeledTypedPrice[] | null): TypedPrice[] => {
  const priceArray: TypedPrice[] = [];
  prices?.forEach((price) => {
    if (price?.price.value && price.price.currencyDetails?.code) {
      priceArray.push({
        amount: price.price.value,
        currencyCode: price.price.currencyDetails.code,
        formatted: price.price.formattedValue ?? '',
        type: price.type === 'sale' ? 'retail' : price.type,
      });
    }
  });
  return priceArray;
};

export const parseFuelValues = (fueltype?: string): FuelTypes => {
  switch (fueltype) {
    case 'Elektro':
      return FuelTypes.ELECTRICAL;
    case 'Diesel':
      return FuelTypes.DIESEL;
    case 'Gas':
      return FuelTypes.HYDROGEN;
    case 'Hybrid':
      return FuelTypes.PETROL;
    default:
      return FuelTypes.PETROL;
  }
};
export const parseEngineValues = (fueltypeCode?: string): EngineType => {
  switch (fueltypeCode) {
    case 'E':
      return EngineType.PEV;
    case 'D':
      return EngineType.ICE;
    case 'G':
      return EngineType.ICE;
    case 'H':
      return EngineType.OVC_HEV;
    default:
      return EngineType.ICE;
  }
};
const parseConsumptionAndEmissionScope = (
  measurement?: StockCarConsumptionAndEmissionScope | null
) => {
  const consolidatedUnit =
    measurement?.types[0]?.values.find((value) => value.id === 'COMBINED')?.unitText ?? '-';
  const consolidatedValue =
    measurement?.types[0]?.values.find((value) => value.id === 'COMBINED')?.valueText ?? '-';
  return {
    consolidatedUnit,
    consolidatedValue,
    fuel: measurement?.types.map((type) => {
      return {
        unit: type.values.find((value) => value.id === 'COMBINED')?.unitText ?? '-',
        consolidated: type.values.find((value) => value.id === 'COMBINED')?.valueText ?? '-',
        fuel: type.id,
        dataSet: {
          em: 'PURE',
          isDefault: true,
          values: {
            combined: type.values.find((value) => value.id === 'COMBINED')?.valueText ?? '-',
            extraHigh: type.values.find((value) => value.id === 'EXTRA_HIGH')?.valueText ?? '-',
            high: type.values.find((value) => value.id === 'HIGH')?.valueText ?? '-',
            low: type.values.find((value) => value.id === 'LOW')?.valueText ?? '-',
            medium: type.values.find((value) => value.id === 'MEDIUM')?.valueText ?? '-',
          },
        },
      };
    }),
  };
};
export const parseFuels = (engine: StockCarEngine) => {
  if (engine.consumption === null || undefined || engine.emission === null || undefined) {
    return [];
  }
  const consumption = engine.consumption?.engineTypes.map((engineType) => {
    const wltp = parseConsumptionAndEmissionScope(engineType.wltp);
    const nedc = parseConsumptionAndEmissionScope(engineType.nedc);
    return {
      fuel: engineType.id,
      consolidated: {
        unit: wltp?.consolidatedUnit ?? '-',
        value: wltp?.consolidatedValue ?? '-',
      },
      wltp: wltp.fuel,
      nedc: nedc.fuel,
    };
  });
  const emission = engine.emission?.engineTypes.map((engineType) => {
    const wltp = parseConsumptionAndEmissionScope(engineType.wltp);
    const nedc = parseConsumptionAndEmissionScope(engineType.nedc);
    return {
      co2: {
        consolidated: {
          unit: wltp?.consolidatedUnit ?? '-',
          value: wltp?.consolidatedValue ?? '-',
        },
        wltp: wltp.fuel,
        nedc: nedc.fuel,
      },
    };
  });
  const fuels: Fuel[] = [];
  if (consumption && emission) {
    consumption.forEach((consumptionItem, i) => {
      const emissionItem = emission[i];
      consumptionItem.wltp?.forEach((fuel, index) => {
        if (fuel) {
          fuels.push({
            fuel: parseFuelValues(fuel.fuel),
            fuelConsumptionUnit: fuel.unit,
            fuelUnit: fuel.unit,
            consumption: {
              consolidated: {
                unit: fuel.unit ?? '-',
                value: fuel.consolidated ?? '-',
              },
              wltp: {
                consolidated: fuel.consolidated ?? '-',
                unit: fuel.unit ?? '-',
                dataSets: [fuel.dataSet],
              },
              nedc: {
                consolidated: fuel.consolidated ?? '-',
                unit: fuel.unit ?? '-',
                dataSets: consumptionItem?.nedc ? [consumptionItem?.nedc[index].dataSet] : [],
              },
            },
            emission: {
              co2: {
                consolidated: {
                  value:
                    emissionItem.co2.wltp && emissionItem.co2.wltp[index]
                      ? emissionItem.co2.wltp[index].consolidated
                      : '-',
                  unit:
                    emissionItem.co2.wltp && emissionItem.co2.wltp[index]
                      ? emissionItem.co2.wltp[index].unit
                      : '-',
                },
                wltp: {
                  consolidated:
                    emissionItem.co2.wltp && emissionItem.co2.wltp[index]
                      ? emissionItem.co2.wltp[index].consolidated
                      : '-',
                  unit:
                    emissionItem.co2.wltp && emissionItem.co2.wltp[index]
                      ? emissionItem.co2.wltp[index].unit
                      : '-',
                  dataSets:
                    emissionItem.co2.wltp && emissionItem.co2.wltp[index]
                      ? [emissionItem.co2.wltp[index].dataSet]
                      : [],
                },
                nedc: {
                  consolidated: emissionItem.co2.nedc
                    ? emissionItem.co2.nedc[index].consolidated
                    : '-',
                  unit: emissionItem.co2.nedc ? emissionItem.co2.nedc[index].unit : '-',
                  dataSets: emissionItem.co2.nedc ? [emissionItem.co2.nedc[index].dataSet] : [],
                },
              },
            },
          });
        }
      });
    });
  }
  return fuels;
};

export const parseEmissionNorm = (
  emissionClassToParse?: string,
  emissionDecalCode?: string | null
) => {
  let emissionNorm: string | undefined;
  const pollutionBadge: CodeDescription = {
    code: emissionDecalCode ? `pollution-badge-type.${emissionDecalCode}` : '',
    description: '',
  };
  switch (emissionClassToParse) {
    case 'EU6':
      emissionNorm = 'Euro 6';
      break;
    case 'EU5':
      emissionNorm = 'Euro 5';
      break;
    case 'EU4':
      emissionNorm = 'Euro 4';
      break;
    default:
      emissionNorm = undefined;
  }
  return { emissionNorm, pollutionBadge };
};

export function parseGraphQlToVehicleBasic(
  countryCode: string,
  langCode: string,
  data?: StockCar | null
) {
  if (!data) {
    return undefined;
  }

  const {
    salesInfo,
    id,
    dealer,
    emissionDecalCode,
    carline,
    colorInfo,
    cartypeText,
    driveText,
    engineInfo,
    envkv,
    initialRegistrationDateInfo,
    model,
    manufacturerSpecificItems,
    mileage,
    titleText,
    generalInspectionDateInfo,
    weblink,
    carPrices,
    subtitleText,
    techDataGroups,
    gearText,
    commissionNumber,
    paddingTypeText,
    preowners,
    warranties,
    images,
    qualityLabel,
    vatReclaimable,
  } = data;
  const { pictures, tilesPictures } = parseImages(images);
  const gearBox = techDataGroups
    .find((element) => element.id === 'driveline')
    ?.techDataList.find((element) => element.id === 'gearbox');

  const consumptionData = techDataGroups.find(
    (element) => element.id === 'consumption'
  )?.techDataList;
  const symbolicCarlineGroup = // eslint-disable-next-line no-underscore-dangle
    (manufacturerSpecificItems as StockCarManufacturerAudi).cdbItems.find(
      (element) => element.id === 'model-group-range'
    )?.value;

  let envkv2024: Envkv2024Data[];
  // eslint-disable-next-line no-underscore-dangle
  if (envkv?.__typename === 'StockCarEnvkv2024') {
    envkv2024 = [
      {
        key: 'labelUrls',
        values: [
          {
            key: 'PDF',
            value: envkv.labelUrlPDF,
            values: [],
          },
          {
            key: 'SVG',
            value: envkv.labelUrlSVG,
            values: [],
          },
        ],
      },
      {
        key: 'legalCartype',
        value: envkv.legalCartypeCode,
        values: [],
      },
      {
        key: 'consumptionTax',
        unit: envkv.consumptionTax.unitText,
        value: envkv.consumptionTax.text,
        values: [],
      },
      {
        key: 'creationDate',
        value: new Date(envkv.creationDateText).toLocaleDateString(countryCode, {
          timeZone: 'UTC',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
        values: [],
      },
      {
        key: 'co2CostStartYear',
        value: envkv.co2CostStartYearText,
        values: [],
      },
      {
        key: 'co2CostEndYear',
        value: envkv.co2CostEndYearText,
        values: [],
      },
      {
        key: 'fuelTariffReferenceYear',
        value: envkv.fuelTariffReferenceYearText,
        values: [],
      },
      {
        key: 'defaultMileage',
        unit: envkv.defaultMileage.unitText,
        value: envkv.defaultMileage.text,
        values: [],
      },
      {
        key: 'drive',
        value: envkv.driveText,
        values: [],
      },
      {
        key: 'co2Class',
        values: envkv.co2Class.emptyBatteryClassText
          ? [
              {
                key: 'combined',
                value: envkv.co2Class.combinedClassText,
                values: [],
              },
              {
                key: 'emptyBattery',
                value: envkv.co2Class.emptyBatteryClassText,
                values: [],
              },
            ]
          : [
              {
                key: 'combined',
                value: envkv.co2Class.combinedClassText,
                values: [],
              },
            ],
      },
      {
        key: 'co2Costs',
        values: [
          {
            key: 'high',
            values: [
              {
                key: 'costs',
                unit: envkv.co2Costs.high.costs.unitText,
                value: envkv.co2Costs.high.costs.text,
                values: [],
              },
              {
                key: 'tariff',
                unit: envkv.co2Costs.high.tariff.unitText,
                value: envkv.co2Costs.high.tariff.text,
                values: [],
              },
            ],
          },
          {
            key: 'low',
            values: [
              {
                key: 'costs',
                unit: envkv.co2Costs.low.costs.unitText,
                value: envkv.co2Costs.low.costs.text,
                values: [],
              },
              {
                key: 'tariff',
                unit: envkv.co2Costs.low.tariff.unitText,
                value: envkv.co2Costs.low.tariff.text,
                values: [],
              },
            ],
          },
          {
            key: 'medium',
            values: [
              {
                key: 'costs',
                unit: envkv.co2Costs.medium.costs.unitText,
                value: envkv.co2Costs.medium.costs.text,
                values: [],
              },
              {
                key: 'tariff',
                unit: envkv.co2Costs.medium.tariff.unitText,
                value: envkv.co2Costs.medium.tariff.text,
                values: [],
              },
            ],
          },
        ],
      },
      {
        key: 'energyCosts',
        unit: envkv.energyCosts.unitText,
        value: envkv.energyCosts.text,
        values: [],
      },
      {
        key: envkv.primaryFuel.id,
        values: [
          {
            key: 'fuelConsumptionUnit',
            value: envkv.primaryFuel.consumptionUnitText,
            values: [],
          },
          {
            key: 'fuelTariff',
            value: envkv.primaryFuel.tariffText,
            values: [],
          },
          {
            key: 'fuelTariffUnit',
            value: envkv.primaryFuel.tariffUnitText,
            values: [],
          },
          {
            key: 'fuelType',
            value: envkv.primaryFuel.typeText,
            values: [],
          },
          {
            key: 'fuelUnit',
            value: envkv.primaryFuel.consumptionUnitText,
            values: [],
          },
        ],
      },
      {
        key: 'fuelTypes',
        values: [
          {
            key: 'primaryFuel',
            value: envkv.primaryFuel?.id,
            values: [],
          },
        ],
      },
    ];
    if (envkv.secondaryFuel) {
      envkv2024
        .find((element) => element.key === 'fuelTypes')
        ?.values.push({
          key: 'secondaryFuel',
          value: envkv.secondaryFuel.id,
          values: [],
        });
      envkv2024.push({
        key: envkv.secondaryFuel.id,
        values: [
          {
            key: 'fuelConsumptionUnit',
            value: envkv.secondaryFuel.consumptionUnitText,
            values: [],
          },
          {
            key: 'fuelTariff',
            value: envkv.secondaryFuel.tariffText,
            values: [],
          },
          {
            key: 'fuelTariffUnit',
            value: envkv.secondaryFuel.tariffUnitText,
            values: [],
          },
          {
            key: 'fuelType',
            value: envkv.secondaryFuel.typeText,
            values: [],
          },
          {
            key: 'fuelUnit',
            value: envkv.secondaryFuel.consumptionUnitText,
            values: [],
          },
        ],
      });
    }
  }
  const hasConsumptionAndEmission =
    engineInfo?.consumption !== null && engineInfo?.emission !== null;
  const hasWltp =
    hasConsumptionAndEmission &&
    engineInfo?.consumption?.engineTypes[0].wltp !== null &&
    engineInfo?.emission?.engineTypes[0].wltp !== null;
  const hasNedc =
    hasConsumptionAndEmission &&
    engineInfo?.consumption?.engineTypes[0].nedc !== null &&
    engineInfo?.emission?.engineTypes[0].nedc !== null;
  const emissionClassToParse = consumptionData?.find(
    (element) => element.id === 'emission_class'
  )?.text;
  const { pollutionBadge, emissionNorm } = parseEmissionNorm(
    emissionClassToParse,
    emissionDecalCode
  );
  const emissionClass = {
    code: `emissionclass.${emissionNorm}`,
    description: emissionNorm,
  };

  const powerDisplay =
    engineInfo?.maxPowerKW?.value.formatted || engineInfo?.maxPowerPS?.value.formatted
      ? `${
          engineInfo?.maxPowerKW?.value.formatted
            ? `${engineInfo?.maxPowerKW?.value.formatted} ${engineInfo?.maxPowerKW?.unitText}`
            : ''
        }${
          engineInfo?.maxPowerPS?.value.formatted
            ? ` (${engineInfo?.maxPowerPS?.value.formatted} ${engineInfo?.maxPowerPS?.unitText})`
            : ''
        }`
      : '';
  const fuels = engineInfo ? parseFuels(engineInfo) : [];

  const io: Io = {
    engineType: parseEngineValues(engineInfo?.fuel?.code),
    hasNedc,
    hasWltp,
    fuels,
  };
  const efficiencyClass = consumptionData?.find(
    (element) => element.id === 'efficiency_class'
  )?.text;
  const availableFromDate = salesInfo?.availableFromDateInfo?.value
    ? Date.parse(salesInfo.availableFromDateInfo.value)
    : 0;
  const dateOffer = salesInfo?.offerDateInfo?.value ? Date.parse(salesInfo.offerDateInfo.value) : 0;

  const geoLocation =
    dealer.position?.latitude && dealer.position?.longitude
      ? {
          lat: dealer.position.latitude,
          lon: dealer.position.longitude,
        }
      : undefined;

  const parsedWarranties = warranties.map((warranty) => {
    return {
      dateBegin: warranty.dateStart ?? '',
      productDurationUnitText: warranty.durationUnitText ?? '',
      productDuration: warranty.durationValue ?? '',
      dateExpiry: warranty.dateEnd ?? '',
      productDurationUnit: warranty.durationUnitCode ?? '',
      text: warranty.label,
      type: warranty.name,
      order: warranty.sortOrder,
      hrefCondition: warranty.documentURL,
      legalEntity: warranty.legalEntity,
    } as Warranty;
  });
  const warrantyPlus = !!qualityLabel.find((element) => element.name === 'GWPLUS');

  const newVehicleBasic: VehicleBasic = {
    audiCode: '',
    availableFrom: salesInfo?.availableFromDateInfo?.value ?? '',
    availableFromDate,
    bodyType: {
      code: cartypeText ?? '',
    },
    buyableOnline: salesInfo?.isBuyOnline ?? false,
    carId: id,
    commissionNumber,
    countryCode: countryCode ?? '-',
    dateOffer,
    dealer: {
      id: dealer.id ?? undefined,
      name: dealer.name ?? undefined,
      imprint: dealer.imprint ?? undefined,
      city: dealer.city ?? undefined,
      country: dealer.country ?? undefined,
      email: dealer.email ?? undefined,
      pageUrl: dealer.website ?? undefined,
      geoLocation,
      phoneNumbers: dealer.phone ? [{ number: dealer.phone, type: '' }] : undefined,
      region: dealer.region ?? undefined,
      street: dealer.street ?? undefined,
      trackingId: dealer.hid ?? undefined,
      zipCode: dealer.zip ?? undefined,
    },
    decodedCarId: id,
    deleted: false,
    driveType: {
      code: driveText ?? '',
    },
    emissionClass,
    entryUrl: weblink ?? '',
    envkvIOData: {
      efficiencyClass,
    },
    exhaust: emissionClass.description,
    envkv2024: envkv2024!,
    extColor: {
      code: colorInfo?.exteriorColor?.colorInfo?.code ?? '',
      description: colorInfo?.exteriorColor?.colorInfo?.text ?? '',
    },
    id,
    pollutionBadge,
    rawDealerId: dealer.id,
    symbolicCarlineGroup: {
      code: symbolicCarlineGroup ?? '',
      description: symbolicCarlineGroup ?? '',
    },
    symbolicCarline: {
      code: carline?.id ?? '',
      description: titleText ?? '',
    },
    typedPrices: carPrices ? parsePrices(carPrices) : [],
    title: titleText ?? '',
    langCode: langCode ?? '-',
    intColor: {
      code: colorInfo?.interiorColor?.colorInfo?.code ?? '',
      description: colorInfo?.interiorColor?.colorInfo?.text ?? '',
    },
    topColor: {
      code: colorInfo?.topColor?.colorInfo?.code ?? '',
    },
    modelCode: {
      code: model?.id.code ?? '',
      description: subtitleText ?? '',
    },
    modelVersion: model?.id.version ?? 0,
    modelYear: model?.id.year ?? 2024,
    productionYear: model?.id.year.toString() ?? '2024',
    noNedc: false,
    pictures,
    fallbackPictures: [
      {
        originalKey: 'render_16x5',
        type: 'fallback',
        key: 'render_16x5',
        url: 'https://vtpapi.audi.de/fallbackimages/aud_vtp_fallback_16_5_logo.jpg',
      },
      {
        originalKey: 'render_16x96',
        type: 'fallback',
        key: 'render_16x96',
        url: 'https://vtpapi.audi.de/fallbackimages/aud_vtp_fallback_16_9_logo.jpg',
      },
      {
        originalKey: 'render_4x3',
        type: 'fallback',
        key: 'render_4x3',
        url: 'https://vtpapi.audi.de/fallbackimages/aud_vtp_fallback_4_3_logo.jpg',
      },
    ],

    salesModelYear: model?.salesModelyear ?? undefined,
    powerDisplay,
    tilesPictures,
    weblink: weblink ?? '',
    io,
    upholsteryType: {
      code: '',
      description: paddingTypeText ?? undefined,
    },
    upholsteryColor: {
      code: '',
      description: colorInfo?.interiorColor?.baseColorInfo?.text ?? undefined,
    },
    // provided id is not matching to the scs values
    gearBox: {
      code: gearBox?.id ?? '',
      description: gearBox?.text,
    },
    // gear type code is not provided by onegraph response
    gearType: {
      code: '',
      description: gearText ?? '',
    },
    brand: {
      code: 'aa',
    },
    fuel: {
      code: engineInfo?.fuel?.code ?? '',
      description: engineInfo?.fuel?.text,
    },
    used: {
      mileageFormatted: mileage?.value.formatted,
      mileageUnit: mileage?.unitText?.toLocaleLowerCase() ?? undefined,
      numPreviousOwners: preowners ?? undefined,
      initialRegistrationDate: initialRegistrationDateInfo?.value
        ? Date.parse(initialRegistrationDateInfo.value)
        : undefined,
      warrantyTypes: [],
    },
    generalinspectionDueDate: generalInspectionDateInfo?.value
      ? Date.parse(generalInspectionDateInfo?.value)
      : undefined,
    generalInspectionString:
      generalInspectionDateInfo?.value && !Date.parse(generalInspectionDateInfo?.value)
        ? generalInspectionDateInfo?.value
        : undefined,
    // dependent on the dealer id at least for the german market
    // for now we use this but this still needs to be clarified
    nationWideSelling: dealer?.id === '03611',
    type: cartypeText ?? '',
    warrantyInfo: {
      warranties: parsedWarranties,
    },
    warrantyPlus,
    // needed but not for germany
    vatReclaimable: vatReclaimable ?? false,
    // not provided by Onegraph
    vehicleDataVersion: '',
    hasCampaigns: false,
    liveConsulting: false,
    vtpDataStoreUrl: '',
    mbvMarket: '',
    envkvData: {},
    symbolicMarketId: '',
    prString: '',
    employeeVehicle: false,
    lastChange: 0,
    leasingCar: false,
    footnotes: {},
    mbvHandbook: {
      code: '',
      description: titleText ?? '',
    },
  };

  return newVehicleBasic;
}
