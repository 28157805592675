import { FormGroup, RadioButton, Select, TextField, Text, } from '@audi/audi-ui-react';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { InfoIcon } from './InfoIcon';
import { DangerousElement } from '../../../DangerousElement';
export const renderRadioField = (field, formName, inputFields, setFormValue) => {
    return (React.createElement(InputWrapper, null,
        React.createElement(RadioWrapper, { spaceStackStart: "m", label: field.label }, field.data.map((fieldValue) => {
            return (React.createElement(React.Fragment, null, fieldValue.text && (React.createElement(WrapperToOverwriteLegacyCSS, null,
                React.createElement(RadioButton, { inputId: `${field.id}_${fieldValue.value}`, name: field.id, value: fieldValue.value, checked: inputFields[formName][field.id] === fieldValue.value, onChange: (evt) => setFormValue(formName, field, evt.target.value) }, `${fieldValue.text} ${field.displayUnit}`)))));
        }))));
};
export const renderSelectField = (field, formName, inputFields, setFormValue, calculate, loading) => {
    return (React.createElement(InputWrapper, null,
        React.createElement(Select, { inputId: field.id, label: field.label, disabled: loading, value: getInputValue(inputFields[formName][field.id]), onChange: (evt) => {
                setFormValue(formName, field, evt.target.value);
                calculate();
            }, required: true }, field.data.map((fieldValue) => (React.createElement("option", { value: fieldValue.value, key: fieldValue.value },
            fieldValue.text,
            " ",
            field.displayUnit))))));
};
export const renderHiddenField = (field, formName, inputFields) => {
    return (React.createElement("input", { type: "hidden", id: field.id, value: getInputValue(inputFields[formName][field.id]) }));
};
export const renderTextField = (field, formName, inputFields, setFormValue, calculate, loading, shiftCurrencySymbolLeftToRight) => {
    return (React.createElement(InputWrapper, null,
        React.createElement(WrapperToOverwriteLegacyCSS, null,
            shiftCurrencySymbolLeftToRight &&
                (field.id === 'DownPayment' || field.id === 'Deposit') && (React.createElement(Text, { as: "p", variant: "copy2" }, field.label)),
            React.createElement(TextField, { className: shiftCurrencySymbolLeftToRight && (field.id === 'DownPayment' || field.id === 'Deposit')
                    ? 'downPayment'
                    : '', inputId: field.id, label: shiftCurrencySymbolLeftToRight && (field.id === 'DownPayment' || field.id === 'Deposit')
                    ? ''
                    : field.label, disabled: loading, value: getInputValue(inputFields[formName][field.id]), onChange: (evt) => {
                    const decimalRegex = new RegExp(/^[0-9, ,, .]+$/);
                    if (field.control.dataType === 'Decimal' &&
                        (evt.target.value === '' || decimalRegex.test(evt.target.value))) {
                        setFormValue(formName, field, evt.target.value);
                    }
                    else if (field.control.dataType !== 'Decimal') {
                        setFormValue(formName, field, evt.target.value);
                    }
                }, onBlur: () => calculate(), required: true, maxLength: parseInt(field.control.maxInputLength, 10) || undefined, inputMode: field.control.dataType === 'Decimal' ? 'decimal' : undefined, extensionEnd: field.displayUnit }))));
};
export const renderReadonlyField = (field, formName, inputFields, i18n, loading, hasHelp, handleClosePopups) => {
    const value = inputFields[formName] ? inputFields[formName][`$${field.id}`] : undefined;
    let readonlyField;
    const InfoIcontainer = styled.div `
    display: ${({ showHelp }) => (showHelp ? 'block' : 'none')};
    width: 16px;
    align-self: center;
    margin-left: var(${(props) => props.theme.responsive.spacing.s});

    div {
      margin: 0px;
    }
  `;
    if (value) {
        readonlyField = (React.createElement(ReadonlyInputWrapper, null,
            React.createElement(WrapperToOverwriteLegacyCSS, null,
                React.createElement(FlexWrapper, null,
                    React.createElement(Text, { variant: "copy2", as: "p", weight: "normal", spaceInlineEnd: "s" }, field.id === 'Rate' ? `${i18n.financeRateLabelSuffix}${field.label}` : field.label),
                    React.createElement(TextField, { readOnly: true, inputId: field.id, value: getInputValue(value), label: "", required: true }),
                    React.createElement(BreakdownValueWrapper, null,
                        React.createElement(ReadOnlyValue, null,
                            React.createElement(Text, { tint: loading ? 'secondary' : 'primary', as: "p", variant: "copy2", weight: "normal" }, getInputValue(value))),
                        React.createElement(InfoIcontainer, { showHelp: hasHelp }, field.help && (React.createElement(InfoIcon, { size: "xs", onOpenOverlay: handleClosePopups, popupContentStyles: popupContentStyles },
                            React.createElement(DangerousElement, { html: field.help })))))))));
    }
    else {
        readonlyField = (React.createElement(InputWrapper, null,
            React.createElement(Label, null,
                React.createElement(Text, { tint: loading ? 'secondary' : 'primary', variant: "copy2", as: "p", weight: "normal" }, field.label))));
    }
    return readonlyField;
};
export const renderRateField = (field, formName, inputFields, i18n, loading) => {
    const value = inputFields[formName] ? inputFields[formName][`$${field.id}`] : undefined;
    const rateField = (React.createElement(ReadonlyInputWrapper, null,
        React.createElement(WrapperToOverwriteLegacyCSS, null,
            React.createElement(RateFlexWrapper, null,
                React.createElement(Text, { variant: "copy2", as: "span" }, `${field.label}${i18n.financeRateLabelSuffix}`),
                React.createElement(TextField, { readOnly: true, inputId: field.id, hint: "", value: getInputValue(value), label: "", required: true }),
                React.createElement(ReadOnlyValue, null,
                    React.createElement(Text, { tint: loading ? 'secondary' : 'primary', variant: "copy2", as: "p", weight: "normal" }, getInputValue(value)))))));
    return rateField;
};
const getInputValue = (val) => {
    if (!val) {
        return '';
    }
    return String(val);
};
export const getFormValues = (generatedFormData) => {
    return generatedFormData.data.reduce((formTarget, form) => {
        formTarget[form.name] = form.parameter.reduce((fieldTarget, field) => {
            if (field.control.type === 'Label' && field.data[0]) {
                fieldTarget[`$${field.id}`] =
                    field.data[0].type === 'percentage' && field.data[0].text
                        ? `${field.data[0].text} ${field.displayUnit}`
                        : field.data[0].formattedValue;
            }
            else if (field.control.type === 'Edit' && field.data[0]) {
                fieldTarget[field.id] =
                    field.hasData && field.data[0] && field.data[0].text
                        ? field.control.dataType === 'decimal'
                            ? parseFloat(field.data[0].text)
                            : field.data[0].text
                        : undefined;
            }
            else if (field.control.type === 'Hidden' && field.data[0]) {
                fieldTarget[field.id] =
                    field.hasData && field.data[0] && field.data[0].text ? field.data[0].text : undefined;
            }
            else if (field.control.type === 'Select') {
                fieldTarget[field.id] = field.default;
            }
            else {
                fieldTarget[field.id] = undefined;
            }
            return fieldTarget;
        }, {});
        return formTarget;
    }, {});
};
export const RadioWrapper = styled((props) => React.createElement(FormGroup, Object.assign({}, props))) `
  display: flex;
  gap: var(${(props) => props.theme.responsive.spacing.l});
  width: 100%;
  flex-wrap: wrap;
`;
const popupContentStyles = css `
  width: 300px;
  max-width: none;
`;
const InputWrapper = styled.div `
  // max-width: 340px;
  width: 90%;
`;
const ReadonlyInputWrapper = styled.div `
  width: 100%;
  span {
    hyphens: auto;
    word-break: break-word;
  }

  p {
    line-height: inherit !important;
  }

  input {
    display: none;
  }
`;
const ReadOnlyValue = styled.div `
  p {
    word-break: keep-all;
    white-space: nowrap;
  }
`;
const BreakdownValueWrapper = styled.div `
  display: inline-flex;
  align-items: center;

  p {
    line-height: inherit !important;
  }
`;
const FlexWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  height: 20px;
  margin-block-end: var(${(props) => props.theme.responsive.spacing.s});
  align-items: center;

  span {
    display: flex;
    align-items: center;

    button {
      margin-bottom: 10px;
    }
  }
`;
const RateFlexWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  height: 20px;
  margin-block-end: 0px;
  align-items: center;

  span {
    display: flex;
    align-items: center;

    button {
      margin-bottom: 10px;
    }
  }
`;
const Label = styled.div `
  color: var(${(props) => props.theme.colors.base.grey[50]});
`;
export const WrapperToOverwriteLegacyCSS = styled.div `
  > .downPayment > div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: space-between;

    > div:first-child {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
    > div:last-child {
      text-align: start;
      padding-left: 0px;
      padding-right: 0px;
      min-width: 15px;
      padding-bottom: 10px;
    }
  }
  input {
    @media screen and (max-width: 479px) {
      height: auto !important;
    }
  }
`;
