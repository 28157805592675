var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from 'react';
import { filterCTAButtons, getFetchOptions, removePhoneButton, searchForPhoneButton, validateEmail, } from './ButtonUtills';
export const useButtons = (vehicle, appContext, configurationService, environmentConfig, showAsText, isTilesButtonMode) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const rawCTAButtons = ((_a = configurationService === null || configurationService === void 0 ? void 0 : configurationService.getConfiguration()) === null || _a === void 0 ? void 0 : _a.cta)
        ? configurationService.getConfiguration().cta
        : [];
    const scContextId = ((_b = configurationService === null || configurationService === void 0 ? void 0 : configurationService.getConfiguration()) === null || _b === void 0 ? void 0 : _b.scContextId)
        ? configurationService.getConfiguration().scContextId
        : undefined;
    const [ctaLiteReservationButton, setCtaLiteReservationButton] = React.useState(undefined);
    const ForcePhoneAsPrimary = ((_c = configurationService === null || configurationService === void 0 ? void 0 : configurationService.getConfiguration()) === null || _c === void 0 ? void 0 : _c.scopes.forcePhoneAsPrimary)
        ? (_d = configurationService === null || configurationService === void 0 ? void 0 : configurationService.getConfiguration()) === null || _d === void 0 ? void 0 : _d.scopes.forcePhoneAsPrimary
        : false;
    const phoneWithNumber = ((_e = configurationService === null || configurationService === void 0 ? void 0 : configurationService.getConfiguration()) === null || _e === void 0 ? void 0 : _e.scopes.phoneWithNumber)
        ? (_f = configurationService === null || configurationService === void 0 ? void 0 : configurationService.getConfiguration()) === null || _f === void 0 ? void 0 : _f.scopes.phoneWithNumber
        : false;
    const liteReservationButton = rawCTAButtons.find((button) => button.type === 'liteReservation');
    const checkDisplayOption = (liteReservationButton === null || liteReservationButton === void 0 ? void 0 : liteReservationButton.displayOption) === appContext ||
        (liteReservationButton === null || liteReservationButton === void 0 ? void 0 : liteReservationButton.displayOption) === 'both';
    const CTAButtons = filterCTAButtons(rawCTAButtons, vehicle, appContext, showAsText, isTilesButtonMode, ctaLiteReservationButton);
    const [initialButtonsState, setinitialButtonsState] = React.useState(CTAButtons);
    const [buttonsState, setButtonsState] = React.useState(CTAButtons);
    const [isDesktop, setIsDesktop] = React.useState(false);
    const PhoneCTA = searchForPhoneButton(CTAButtons, appContext);
    const liteReservationButtonUrl = (_g = liteReservationButton === null || liteReservationButton === void 0 ? void 0 : liteReservationButton.url) !== null && _g !== void 0 ? _g : undefined;
    const fetchOptions = liteReservationButtonUrl
        ? getFetchOptions(environmentConfig, vehicle, liteReservationButton)
        : undefined;
    const showLiteReservedVehicle = validateEmail((_h = vehicle.dealer) === null || _h === void 0 ? void 0 : _h.email) &&
        typeof window !== 'undefined' &&
        environmentConfig.pdfBaseURL;
    useEffect(() => {
        if (checkDisplayOption) {
            const fetchLiteReservationUrl = () => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    const response = yield fetch(liteReservationButtonUrl, fetchOptions);
                    if (response.status === 201 || response.status === 200) {
                        const liteReservationUrl = yield response.text();
                        if (liteReservationUrl && liteReservationUrl.length > 0) {
                            const ctaLiteReservation = {
                                method: 'GET',
                                type: 'liteReservation',
                                url: liteReservationUrl,
                                target: 'new-window',
                                label: liteReservationButton.label,
                                displayOption: liteReservationButton.displayOption,
                                reservation: liteReservationButton.reservation,
                            };
                            let liteReservationFilteredButtons = filterCTAButtons(rawCTAButtons, vehicle, appContext, showAsText, isTilesButtonMode, ctaLiteReservation);
                            if (appContext === 'carinfo' && window.matchMedia('(min-width: 480px)').matches) {
                                liteReservationFilteredButtons = removePhoneButton(liteReservationFilteredButtons);
                            }
                            setButtonsState(liteReservationFilteredButtons);
                            setinitialButtonsState(liteReservationFilteredButtons);
                            setCtaLiteReservationButton(ctaLiteReservation);
                        }
                    }
                }
                catch (_a) {
                    console.error('error while fetching lite reservation url');
                }
            });
            if (showLiteReservedVehicle && liteReservationButton) {
                fetchLiteReservationUrl();
            }
        }
        return () => { };
    }, [liteReservationButton]);
    const setDeviceType = () => {
        if (!window.matchMedia('(min-width: 480px)').matches) {
            setIsDesktop(false);
        }
        else {
            setIsDesktop(true);
        }
    };
    const handlePhoneButton = () => {
        if (!window.matchMedia('(min-width: 480px)').matches) {
            if (ForcePhoneAsPrimary &&
                (appContext === 'carinfo' || (!isTilesButtonMode && showAsText) || isTilesButtonMode)) {
                const buttonsWithoutPhone = removePhoneButton(buttonsState);
                setButtonsState([PhoneCTA, ...buttonsWithoutPhone]);
            }
            else {
                setButtonsState(initialButtonsState);
            }
        }
        else {
            const buttonsWithoutPhone = removePhoneButton(buttonsState);
            if (appContext === 'carinfo')
                setButtonsState(buttonsWithoutPhone);
            else
                setButtonsState(initialButtonsState);
        }
    };
    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            setDeviceType();
            window.addEventListener('resize', setDeviceType);
            if (PhoneCTA) {
                handlePhoneButton();
                window.addEventListener('resize', handlePhoneButton);
                return () => {
                    window.removeEventListener('resize', handlePhoneButton);
                    window.removeEventListener('resize', setDeviceType);
                };
            }
            return () => {
                window.removeEventListener('resize', setDeviceType);
            };
        }
        return undefined;
    }, []);
    return {
        isDesktop,
        buttonsState,
        scContextId,
        phoneWithNumber,
    };
};
