export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DealerSearchSortId: { input: any; output: any; }
  StockCarSearchCriterionId: { input: any; output: any; }
  StockCarSearchCriterionItemId: { input: any; output: any; }
  StockCarSearchSortId: { input: any; output: any; }
  StockCarSearchSpecialCriterionId: { input: any; output: any; }
};

/** information about stocks of a market */
export type AvailableStock = {
  __typename?: 'AvailableStock';
  /** information about the new car stock */
  newCars?: Maybe<Stock>;
  /** information about the used car stock */
  usedCars?: Maybe<Stock>;
};

/** Cache Control scope */
export enum CacheControlScope {
  /**
   * If a cached response has a PRIVATE scope, its value is accessible by only a single user.
   * To enforce this restriction, the cache needs to know how to identify that user.
   * @see: https://www.apollographql.com/docs/apollo-server/performance/caching/#identifying-users-for-private-responses
   */
  Private = 'PRIVATE',
  /**
   * By default, PUBLIC cached responses are accessible by all users.
   * @see https://www.apollographql.com/docs/apollo-server/performance/caching/#separating-responses-for-logged-in-and-logged-out-users
   */
  Public = 'PUBLIC'
}

/** Generic Car, implemented as ConfiguredCar or StockCar */
export type Car = {
  /** Carline information of a car. */
  carline?: Maybe<CarlineBase>;
  /**
   * Unique identifier for a car.
   * Might contain AudiCode, MarketingCode etc.
   */
  code?: Maybe<CarCode>;
  /** Model information of a car. */
  model?: Maybe<CarModelBase>;
  /** Price information of a car. */
  prices?: Maybe<CarPrices>;
};

/**
 * Unique identifier for a car.
 * Might contain AudiCode, MarketingCode etc.
 */
export type CarCode = {
  /** Country that the `id` is valid for. */
  country?: Maybe<Scalars['String']['output']>;
  /**
   * Unique identifier for the stored car.
   * Might be AudiCode, MarketingCode etc.
   */
  id: Scalars['String']['output'];
  /** Language that the `id` is valid for. */
  language?: Maybe<Scalars['String']['output']>;
};

/** Model information */
export type CarModelBase = {
  /** Identifier of the model */
  id?: Maybe<CarModelIdentifier>;
  /** Name of the model */
  name?: Maybe<Scalars['String']['output']>;
};

/**
 * Interface for a model of a car.
 * The values has to be handled as 'one' - they can't be modified or changed in an authoring environment.
 * The fo
 */
export type CarModelIdentifier = {
  /** 6-digit model base code. Describes the model/engine used in a configuration e.g. GENBAE */
  code: Scalars['String']['output'];
  /**
   * 3-digit pr numbers.
   * The extensions are PrNumbers that are part of the model code.
   * These PrNumbers are treated as standard options for this model (e.g. set a specific package or equipment).
   * This extensions can't be deselected in the configuration process. Sometimes this PrNumbers are more like a marker. For example mark the trimline of the model.
   * The handling of extensions can differ in different markets.
   */
  extensions?: Maybe<Array<Scalars['String']['output']>>;
  /** 1-digit model version */
  version: Scalars['Int']['output'];
  /** The year of the catalogue release */
  year: Scalars['Int']['output'];
};

/** A specific price. */
export type CarPrice = {
  /** Market specific currency for the price */
  currencyDetails?: Maybe<Currency>;
  /** Formatted price with currency (e.g. 1.321,50 EUR) */
  formattedValue?: Maybe<Scalars['String']['output']>;
  /** Raw value of the price (e.g. 1321.5) */
  value: Scalars['Float']['output'];
  /** Formatted price without currency (e.g. 1.321,50) */
  valueAsText?: Maybe<Scalars['String']['output']>;
};

/** All available prices for a car. */
export type CarPrices = {
  /** Total price of the car */
  total?: Maybe<CarPrice>;
};

/** information about a carline in the stocks of a market */
export type CarlineAvailableStock = {
  __typename?: 'CarlineAvailableStock';
  /** information about the new car stock */
  newCars?: Maybe<Stock>;
  /** information about the used car stock */
  usedCars?: Maybe<Stock>;
};

/** Carline information */
export type CarlineBase = {
  /** Id of the carline (a1sb) */
  id: Scalars['String']['output'];
  /** Localized name of the carline (A1 Sportback) */
  name: Scalars['String']['output'];
};

export type CarlineGroup = {
  __typename?: 'CarlineGroup';
  /** List of carlines */
  carlines: Array<CarlineStructureCarline>;
  /** Unique carline group identifier */
  identifier: CarlineIdentifier;
  /** Localized, human readable display name, e.g. 'A3' */
  name: Scalars['String']['output'];
};


export type CarlineGroupCarlinesArgs = {
  carlinesInput?: InputMaybe<CarlinesInput>;
};

export type CarlineIdentifier = {
  __typename?: 'CarlineIdentifier';
  /**
   * KVPS brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['output'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars['String']['output'];
  /** Unique, machine readable carline id, e.g. `a3sb` */
  id: Scalars['String']['output'];
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars['String']['output'];
};

/** Describes the carline structure for a specific brand, country and language. */
export type CarlineStructure = {
  __typename?: 'CarlineStructure';
  /** List of carline groups */
  carlineGroups: Array<CarlineGroup>;
};

export type CarlineStructureCarline = {
  __typename?: 'CarlineStructureCarline';
  /** this field passes the value of input `CarlinesInput.includeNonConfigurables` from `CarlineGroup.carlines` through to carline-info subgraph */
  _includeNonConfigurables?: Maybe<Scalars['Boolean']['output']>;
  /**
   * information about the carline in the stocks of the market.
   * the market is selected by the information in the `CarlineIdentifier`
   */
  availableStock?: Maybe<CarlineAvailableStock>;
  /** Unique carline identifier */
  identifier: CarlineIdentifier;
  /** Localized, human readable display name, e.g. 'A3 Sportback' */
  name: Scalars['String']['output'];
};


export type CarlineStructureCarlineAvailableStockArgs = {
  partnerInfo?: InputMaybe<PartnerInfo>;
};

/** options for filter (reduce number of) carlines */
export type CarlineStructureForStockCarsCarlineFilterInput = {
  /**
   * hide all carlines, which are currently not available in selected stock
   *
   * NEW = "carlineGroups[].carlines[].availableStock.newCars.amount" is zero
   * USED = "carlineGroups[].carlines[].availableStock.usedCars.amount" is zero
   * NEW_AND_USED = "carlineGroups[].carlines[].availableStock.newCars.amount" and "carlineGroups[].carlines[].availableStock.usedCars.amount" are zero
   * NEW_OR_USED = "carlineGroups[].carlines[].availableStock.newCars.amount" or "carlineGroups[].carlines[].availableStock.usedCars.amount" are zero
   */
  hideNotAvailableInCarsStock?: InputMaybe<StockCarsTypeCombinationInput>;
};

/** options for filter (reduce number of) carline groups */
export type CarlineStructureForStockCarsCarlineGroupsFilterInput = {
  /** hide all carline groups without carlines (e.g. all are filtered by other options) */
  hideWithNoCarlines?: InputMaybe<Scalars['Boolean']['input']>;
};

/** options for filter (reduce number of) carline groups and/or carlines */
export type CarlineStructureForStockCarsFilterInput = {
  /** options for filter (reduce number of) carline groups */
  carlineGroups?: InputMaybe<CarlineStructureForStockCarsCarlineGroupsFilterInput>;
  /** options for filter (reduce number of) carlines */
  carlines?: InputMaybe<CarlineStructureForStockCarsCarlineFilterInput>;
};

/**
 * taken from the carline-info subgraph. not used in stockcar subgraph.
 * the field `includeNonConfigurables` is passed through to carline-info subgraph by field `CarlineStructureCarline._includeNonConfigurables`
 *
 * Carlines configuration input
 */
export type CarlinesInput = {
  includeNonConfigurables?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ConfiguredCar = {
  __typename?: 'ConfiguredCar';
  /**
   * taken from the cola subgraph
   * used for getting the pr numbers as reference for seeking the similar cars
   */
  _selectedPr7?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   * taken from the cola subgraph
   * used as federation entry key and as reference for seeking the similar cars
   */
  id: ConfiguredCarIdentifier;
  /** similar cars in new car stock and used car stock with this configured car as reference */
  similarCars?: Maybe<SimilarCarsStock>;
};

/**
 * taken from the cola subgraph for @key of ConfiguredCar
 *
 * Identifier to query a configured car
 */
export type ConfiguredCarIdentifier = {
  __typename?: 'ConfiguredCarIdentifier';
  /** KVPS brand id */
  brand: Scalars['String']['output'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars['String']['output'];
  /** 3-digit pr numbers */
  equipmentOptions?: Maybe<Array<Scalars['String']['output']>>;
  /** 4-digit pr number of the exterior color */
  exteriorColor?: Maybe<Scalars['String']['output']>;
  /** 2-digit pr number of the interior color */
  interiorColor?: Maybe<Scalars['String']['output']>;
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars['String']['output'];
  model: ConfiguredCarModelIdentifier;
  /** 5-digit sales group */
  salesGroup: Scalars['String']['output'];
};

/**
 * taken from the cola subgraph for @key of ConfiguredCar
 *
 * Model identifier
 */
export type ConfiguredCarModelIdentifier = CarModelIdentifier & {
  __typename?: 'ConfiguredCarModelIdentifier';
  /** 6-digit model base code. Describes the model/engine used in a configuration e.g. 8WCAYG */
  code: Scalars['String']['output'];
  /** 7-digit pr numbers */
  extensions?: Maybe<Array<Scalars['String']['output']>>;
  /** 1-digit model version */
  version: Scalars['Int']['output'];
  /** The year of the catalogue release */
  year: Scalars['Int']['output'];
};

/** Currency information */
export type Currency = {
  __typename?: 'Currency';
  /** ISO 4217 currency code. For example, "USD" */
  code: Scalars['ID']['output'];
  /** Symbol of the currency. For example, "$" */
  symbol?: Maybe<Scalars['String']['output']>;
};

/** information about a dealer of a car */
export type DealerInfo = {
  __typename?: 'DealerInfo';
  /** city of dealer address */
  city: Scalars['String']['output'];
  /** ISO Alpha3 country code / KVPS country */
  country: Scalars['String']['output'];
  /** e mail address of dealer */
  email?: Maybe<Scalars['String']['output']>;
  /** fax number of dealer */
  fax?: Maybe<Scalars['String']['output']>;
  /** KVPS number of dealer */
  hid: Scalars['String']['output'];
  /** unique id of a dealer in a market */
  id: Scalars['String']['output'];
  /** imprint of dealer */
  imprint?: Maybe<Scalars['String']['output']>;
  /** name of dealer */
  name: Scalars['String']['output'];
  /** phone number of dealer */
  phone?: Maybe<Scalars['String']['output']>;
  /** geo location of dealer */
  position?: Maybe<GeoLocation>;
  /** region of dealer address (e.g. state) */
  region?: Maybe<Scalars['String']['output']>;
  /** street of dealer address */
  street?: Maybe<Scalars['String']['output']>;
  /** link to dealers page */
  website?: Maybe<Scalars['String']['output']>;
  /** zip of dealer address */
  zip: Scalars['String']['output'];
};

/** result of a dealer search */
export type DealerSearch = {
  __typename?: 'DealerSearch';
  /** number of found dealers */
  resultNumber: Scalars['Int']['output'];
  /** subset of found dealers */
  results: DealerSearchResults;
};

/**
 * "new input"
 *
 * parameter for geo search of dealers
 */
export type DealerSearchParameterGeoCriterionInput = {
  /** latitude of geo position (center of search circle) */
  latitude: Scalars['Float']['input'];
  /** longitude of geo position (center of search circle) */
  longitude: Scalars['Float']['input'];
  /** maximum distance between passed geo position (latitude and longitude) and geo position of a found dealer (radius of search circle) */
  maxDistance: Scalars['Float']['input'];
};

/**
 * "new input"
 *
 * additional parameter for dealer search: search criteria, sorting and paging
 */
export type DealerSearchParameterInput = {
  /** parameter for geographic proximity search */
  geo?: InputMaybe<DealerSearchParameterGeoCriterionInput>;
  /**
   * parameter for search by dealer name
   * the search value could be only a part the name
   */
  name?: InputMaybe<Scalars['String']['input']>;
  /** parameter for paging */
  paging?: InputMaybe<DealerSearchParameterPagingInput>;
  /** parameter for sorting */
  sort?: InputMaybe<DealerSearchParameterSortInput>;
};

/** new input */
export type DealerSearchParameterPagingInput = {
  /** limitation of results */
  limit: Scalars['Int']['input'];
  /** offset set (first element) */
  offset: Scalars['Int']['input'];
};

/** new input */
export type DealerSearchParameterSortInput = {
  /** selected sort direction */
  direction: DealerSearchSortDirection;
  /** Unique identifier for a sort option */
  id: Scalars['DealerSearchSortId']['input'];
};

/** list of found dealers */
export type DealerSearchResults = {
  __typename?: 'DealerSearchResults';
  /** found dealers */
  dealers: Array<DealerSearchResultsDealer>;
  /** applied paging parameter */
  paging?: Maybe<DealerSearchResultsPaging>;
  /** applied sort parameter */
  sort?: Maybe<DealerSearchResultsSort>;
};

/** data of a single found dealer */
export type DealerSearchResultsDealer = {
  __typename?: 'DealerSearchResultsDealer';
  /** common dealer data */
  dealer: DealerInfo;
};

/** Information for dealer search results paging */
export type DealerSearchResultsPaging = {
  __typename?: 'DealerSearchResultsPaging';
  /** limit set */
  limit: Scalars['Int']['output'];
  /** offset set (first element) */
  offset: Scalars['Int']['output'];
};

/** Input parameter that was handed over for dealer search */
export type DealerSearchResultsSort = {
  __typename?: 'DealerSearchResultsSort';
  /** selected sort direction */
  direction: DealerSearchSortDirection;
  /** Unique identifier for a sort option */
  id: Scalars['DealerSearchSortId']['output'];
};

/** new enum */
export enum DealerSearchSortDirection {
  /** ascending sort direction */
  Asc = 'ASC',
  /** descending sort direction */
  Desc = 'DESC'
}

/** geo location with latitude and longitude */
export type GeoLocation = {
  __typename?: 'GeoLocation';
  /** latitude of geo location */
  latitude: Scalars['Float']['output'];
  /** longitude of geo location */
  longitude: Scalars['Float']['output'];
};

/** Used as a container to provide price information. */
export type LabeledTypedPrice = {
  __typename?: 'LabeledTypedPrice';
  /** Disclaimers for price items. At the moment only for financing rate. */
  disclaimers?: Maybe<Array<Maybe<StockCarPriceDisclaimer>>>;
  /** text information about the price, like 'Price' */
  label?: Maybe<Scalars['String']['output']>;
  /** price information with values and currency details */
  price: Price;
  /** type of price, like 'Sale', 'List' */
  type: Scalars['String']['output'];
};

/** information to specify a market */
export type MarketIdentifierInput = {
  /** KVPS brand id. For example `A` for Audi. */
  brand: Scalars['String']['input'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars['String']['input'];
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars['String']['input'];
};

export type OneGraphInfo = {
  __typename?: 'OneGraphInfo';
  /** Version of the OneGraph */
  version: Scalars['String']['output'];
};

/**
 * There are currently two locations in the info graph where service health might be indicated
 * - OneGraphSubService: Should indicate health of one (or more) downstreamservice
 * - OneGraphDownStreamService: Should health of downstream service. This should be requested by the SubService
 */
export type OneGraphServiceHealth = {
  __typename?: 'OneGraphServiceHealth';
  /**
   * URL of the check endpoint (might be important for troubleshooting and support)
   * @deprecated Removed without replacement, so that check urls are not exposed.
   */
  checkEndpoint?: Maybe<Scalars['String']['output']>;
  /** Code (might be an error code of the Sub/DownStream-Service or http error code) */
  code?: Maybe<Scalars['String']['output']>;
  /** Message related to the code */
  message?: Maybe<Scalars['String']['output']>;
  /** Status of the Service */
  status: OneGraphServiceStatusEnum;
};

/**
 * Indicates the service status
 * Derived from https://datatracker.ietf.org/doc/html/draft-inadarei-api-health-check-05#section-3.1
 */
export enum OneGraphServiceStatusEnum {
  /** Failure in Service */
  Fail = 'FAIL',
  /** Service is ok */
  Pass = 'PASS',
  /** Warning in Service */
  Warn = 'WARN'
}

export type OneGraphSubServiceStatus = {
  __typename?: 'OneGraphSubServiceStatus';
  _commit: Scalars['String']['output'];
  _date: Scalars['String']['output'];
  _dependencies: Array<StockCarSubServiceStatusDependency>;
  _ref: Scalars['String']['output'];
  /** Health status of the Subgraph service */
  health: OneGraphServiceHealth;
  /** The Name of the Subgraph service */
  name: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

/** information to specify a dealer */
export type PartnerInfo = {
  /** use data of the dealer group instead of a single dealer */
  includeGroupStock?: InputMaybe<Scalars['Boolean']['input']>;
  /** KVPS id of the dealer */
  partnerId: Scalars['String']['input'];
};

export type Price = CarPrice & {
  __typename?: 'Price';
  /** @deprecated No longer supported */
  currency?: Maybe<Scalars['String']['output']>;
  /** currency code and symbol like 'EUR' and '€' */
  currencyDetails?: Maybe<Currency>;
  /** price value with currency symbol like '5.100,00 €' */
  formattedValue?: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer supported */
  rawValue?: Maybe<Scalars['String']['output']>;
  /** unformatted value as Float like '5.100' */
  value: Scalars['Float']['output'];
  /** formatted price value as text like '5.100,00' */
  valueAsText?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** information about the stocks of a market */
  availableStock: AvailableStock;
  carlineStructureForStockCars: CarlineStructure;
  /**
   * new query
   *
   * search dealers in a stock
   */
  dealerSearch: DealerSearch;
  /**
   * New query to get information for a single stock car
   * See RFC62 for more details
   */
  stockCar?: Maybe<StockCar>;
  /** query to get extended information for a single stock car */
  stockCarExtended?: Maybe<StockCarExtended>;
  /** new query */
  stockCarSearch: StockCarSearch;
  /** new query */
  stockCarSearchConfiguration: StockCarSearchConfiguration;
  /**
   * New query for checking the health status of the subgraph
   * See RFC005 for more details
   */
  stockcarServiceStatus: OneGraphSubServiceStatus;
};


export type QueryAvailableStockArgs = {
  marketIdentifier: MarketIdentifierInput;
  partnerInfo?: InputMaybe<PartnerInfo>;
};


export type QueryCarlineStructureForStockCarsArgs = {
  filter?: InputMaybe<CarlineStructureForStockCarsFilterInput>;
  stockIdentifier: StockIdentifierInput;
};


export type QueryDealerSearchArgs = {
  searchParameter?: InputMaybe<DealerSearchParameterInput>;
  stockIdentifier: StockIdentifierInput;
};


export type QueryStockCarArgs = {
  stockCarIdentifier: StockCarIdentifierInput;
};


export type QueryStockCarExtendedArgs = {
  stockCarIdentifier: StockCarIdentifierInput;
};


export type QueryStockCarSearchArgs = {
  searchParameter?: InputMaybe<StockCarSearchParameterInput>;
  stockIdentifier: StockIdentifierInput;
};


export type QueryStockCarSearchConfigurationArgs = {
  input: StockIdentifierInput;
};

/** Information for searchgroup depending on passed parameter */
export type SearchGroupNode = {
  __typename?: 'SearchGroupNode';
  /** Nodes in tree */
  nodes: Array<SearchGroupNode>;
  /** selectable search criteria */
  selectable?: Maybe<Selectable>;
  /** Text for searchgroup depending on level in tree */
  text: Scalars['String']['output'];
};

/** Selectable search criteria */
export type Selectable = {
  __typename?: 'Selectable';
  /** search criterion depending on parameter for search group */
  criterion: Scalars['String']['output'];
  /** Item code */
  item: Scalars['String']['output'];
};

/** single found similar car */
export type SimilarCar = {
  __typename?: 'SimilarCar';
  /**
   * "new field"
   *
   * differences between similar car and reference configured car
   */
  compareCriteria: Array<SimilarCarCompareCriterion>;
  /** distance between geo position of the car and passed geo position from geo search */
  geoDistance?: Maybe<StockCarFloatValueWithUnitItem>;
  /** score of matching of similar car with reference in percent */
  score: Scalars['Float']['output'];
  /** information about the similar car */
  stockCar: StockCar;
};


/** single found similar car */
export type SimilarCarCompareCriteriaArgs = {
  status: Array<SimilarCarCompareCriterionStatus>;
};

/** single difference between similar car and reference configured car */
export type SimilarCarCompareCriterion = {
  __typename?: 'SimilarCarCompareCriterion';
  /** category of difference */
  category: SimilarCarCompareCriterionCategory;
  /** language independent code of difference */
  code: Scalars['String']['output'];
  /** status of difference in relation to reference configured car */
  status: SimilarCarCompareCriterionStatus;
  /** language dependent display text of difference */
  text: Scalars['String']['output'];
};

/** category of difference between similar car and reference configured car */
export type SimilarCarCompareCriterionCategory = {
  __typename?: 'SimilarCarCompareCriterionCategory';
  /** language independent code of category */
  code: Scalars['String']['output'];
  /** language dependent display text of category */
  text: Scalars['String']['output'];
};

/**
 * status of difference between similar car and reference configured car
 * (in relation to reference configured car)
 */
export enum SimilarCarCompareCriterionStatus {
  Additional = 'additional',
  Missing = 'missing'
}

/** result of similar cars search */
export type SimilarCars = {
  __typename?: 'SimilarCars';
  /** found similar cars */
  cars: Array<SimilarCar>;
};

/** settings for the precision of the model (code and year) for seeking similar cars */
export type SimilarCarsCarModelPrecisionInput = {
  /**
   * precision of the mbv model code of the reference configured car.
   * The value must be between 0 and 6. 6 is default.
   * It defines the number of the digits of the mbv model code of the reference configured car,
   * that must be matched in the similar cars.
   */
  codePrecision?: InputMaybe<Scalars['Int']['input']>;
  /**
   * range of model years for similar cars (based on the model year of the reference configured car).
   * By default the model year of the reference configured car is set.
   */
  yearsOffset?: InputMaybe<SimilarCarsCarModelYearRangeByOffsetInput>;
};

/**
 * range of model years for similar cars
 * the values are offsets based on the model year of the reference configured car
 * e.g. for the reference year 2023
 * 2 = 2025
 * 1 = 2024
 * 0 = 2023
 * -1 = 2022
 * -2 = 2021
 */
export type SimilarCarsCarModelYearRangeByOffsetInput = {
  end: Scalars['Int']['input'];
  start: Scalars['Int']['input'];
};

/**
 * weights for calculate the score of a similar car.
 * Each weight is defined for one feature type (based on a KeyTool equipment category).
 * So each feature with this feature type gets the defined weight.
 * The value must be a positive integer. Default is 100.
 */
export type SimilarCarsFeatureTypeWeightsInput = {
  engineAndTransmission?: InputMaybe<Scalars['Int']['input']>;
  exterior?: InputMaybe<Scalars['Int']['input']>;
  infotainment?: InputMaybe<Scalars['Int']['input']>;
  interior?: InputMaybe<Scalars['Int']['input']>;
  other?: InputMaybe<Scalars['Int']['input']>;
  seats?: InputMaybe<Scalars['Int']['input']>;
  steeringWheels?: InputMaybe<Scalars['Int']['input']>;
  technologyAndSafety?: InputMaybe<Scalars['Int']['input']>;
  wheelsAndTyres?: InputMaybe<Scalars['Int']['input']>;
};

/** parameter for getting similar cars */
export type SimilarCarsInput = {
  /** prefiltering criteria for similar cars */
  additionalParameters?: InputMaybe<Array<StockCarSearchParameterCriterionInput>>;
  /** special prefiltering criteria for similar cars */
  additionalSpecialParameters?: InputMaybe<Array<StockCarSearchParameterSpecialCriterionInput>>;
  /**
   * "new field"
   *
   * parameter for geo search of stock cars
   */
  geoSearch?: InputMaybe<StockCarSearchParameterGeoCriterionInput>;
  /** maximal number of returned similar cars. Default is 10. Maximum is 50. */
  maxNumberOfCars?: InputMaybe<Scalars['Int']['input']>;
  /**
   * By default the similar car should have the same model (code and year) like the reference configured car.
   * With this settings the precision of matching can be adjust.
   */
  modelPrecision?: InputMaybe<SimilarCarsCarModelPrecisionInput>;
  /**
   * "new field"
   *
   * define the order of the returned similar cars
   * default is SCORE
   */
  sortCriterion?: InputMaybe<SimilarCarsSortCriterion>;
  /**
   * by default all parameter have the same relevance by calculate the score
   * with this settings the weights can be changed
   */
  weights?: InputMaybe<SimilarCarsWeightsInput>;
};

/**
 * "new enum"
 *
 * define the order of the returned similar cars
 */
export enum SimilarCarsSortCriterion {
  /**
   * Distance from closest.
   * If vehicles have same distance - first with highest score.
   * If score same - show cheapest.
   */
  Distance = 'DISTANCE',
  /**
   * Price from low to high.
   * If vehicles have same price - first with highest score.
   * If score same - first closest to user by distance.
   */
  Price = 'PRICE',
  /**
   * Score from high to low.
   * If vehicles have same score - first closest to user by distance.
   * If distance is same - show cheapest.
   */
  Score = 'SCORE'
}

/** similar cars in new car stock and used car stock */
export type SimilarCarsStock = {
  __typename?: 'SimilarCarsStock';
  /** similar cars in the new car stock */
  newCars?: Maybe<SimilarCars>;
  /** similar cars in the used car stock */
  usedCars?: Maybe<SimilarCars>;
};


/** similar cars in new car stock and used car stock */
export type SimilarCarsStockNewCarsArgs = {
  input?: InputMaybe<SimilarCarsInput>;
};


/** similar cars in new car stock and used car stock */
export type SimilarCarsStockUsedCarsArgs = {
  input?: InputMaybe<SimilarCarsInput>;
};

/** weights for calculate the score of a similar car */
export type SimilarCarsWeightsInput = {
  /** weights by feature type (based on KeyTool equipment categories) */
  featureTypeWeights?: InputMaybe<SimilarCarsFeatureTypeWeightsInput>;
};

/** information about one stock */
export type Stock = {
  __typename?: 'Stock';
  /** number of cars */
  amount: Scalars['Int']['output'];
  /** url for stock frontend */
  url: Scalars['String']['output'];
};

/** information about a Stock-Car */
export type StockCar = Car & {
  __typename?: 'StockCar';
  /** required information for Webstreaming */
  avpCode?: Maybe<Scalars['String']['output']>;
  /** field that delivers the businessModelCode for example "dealer_stock" */
  businessModelCode: Scalars['String']['output'];
  /** list of active campaigns which includes this stock car */
  campaigns: Array<StockCarCampaign>;
  /** Information about different prices types of a car */
  carPrices?: Maybe<Array<LabeledTypedPrice>>;
  /** Carline information of a car. */
  carline?: Maybe<StockCarCarline>;
  /** Kind of car. New car or used car. */
  cartypeText?: Maybe<Scalars['String']['output']>;
  /** information about the AudiCode of a car */
  code: StockCarCarCode;
  /**
   * "new field"
   *
   * Information about colors of a car, structured by interior-, exterior- and top color
   */
  colorInfo?: Maybe<StockCarColorInfo>;
  /** commission number of car(unique for a dealer in a stock) */
  commissionNumber: Scalars['String']['output'];
  /** information about a dealer of a car */
  dealer: DealerInfo;
  /** Information about the dealer group for this vehicle */
  dealerGroup?: Maybe<StockCarDealerGroup>;
  /** Free text provided by dealer, can contain HTML. */
  descriptionByDealer?: Maybe<Scalars['String']['output']>;
  /** Number of doors */
  doors?: Maybe<Scalars['Int']['output']>;
  /** Kind of drive. For example Frontwheeldrive. */
  driveText?: Maybe<Scalars['String']['output']>;
  /** Value of the selected dynamicAttribute queried by the id */
  dynamicAttributeValue?: Maybe<Scalars['String']['output']>;
  /** All dynamicAttributes for a car */
  dynamicAttributes: Array<StockCarStringItemWithId>;
  /** ELVIS key for emission decal */
  emissionDecalCode?: Maybe<Scalars['String']['output']>;
  /** Engine information of a car. */
  engineInfo?: Maybe<StockCarEngine>;
  /** EnVKV information */
  envkv?: Maybe<StockCarEnvkv>;
  /** StockCar equipment features provided from Carport/VehicleLifecycleService */
  features: Array<StockCarFeature>;
  /** all functions on demand (FoD) for the stock car */
  functionsOnDemand: Array<Maybe<StockCarFunctionOnDemand>>;
  /** Kind of emis badge like "D" */
  gearText?: Maybe<Scalars['String']['output']>;
  /** Information about general inspection date; like value:"neu", type:"text" */
  generalInspectionDateInfo?: Maybe<StockCarDateInfo>;
  /** list of categories grouping mapped generic equipments */
  genericEquipmentCategories: Array<StockCarGenericEquipmentCategory>;
  /** list of generic equipment items mapped to features or assigned manually */
  genericEquipments: Array<StockCarGenericEquipment>;
  /** unique id of a car in a stock */
  id: Scalars['String']['output'];
  /** images of car */
  images?: Maybe<Array<StockCarImage>>;
  /** Information about initial registration date; like value:"2023-06-24T16:11:17+02:00", type:"timestamp" */
  initialRegistrationDateInfo?: Maybe<StockCarDateInfo>;
  /** information to describe manufacturer specific attributes of a car */
  manufacturerSpecificItems: StockCarManufacturer;
  /** Mileage of a car described by unit, value and formatted value. */
  mileage?: Maybe<StockCarFloatValueWithUnitItem>;
  /** Model information of a car. */
  model?: Maybe<StockCarModel>;
  /** numberplate value for a single car */
  numberplate?: Maybe<Scalars['String']['output']>;
  /** numberplate indicator for a single car */
  numberplateIndicator?: Maybe<Scalars['String']['output']>;
  /** Kind of padding. For example "Leather seating surfaces". */
  paddingTypeText?: Maybe<Scalars['String']['output']>;
  /** Key and translated text for pre used information, like "School bus". */
  preUse?: Maybe<StockcarCodedTextItem>;
  /** Number of previous owners of a car. */
  preowners?: Maybe<Scalars['Int']['output']>;
  /**
   * Price information of a car.
   * @deprecated Use carPrices
   */
  prices?: Maybe<StockCarCarPrices>;
  /** StockCar quality labels, like 'GWPLUS' */
  qualityLabel: Array<StockCarQualityLabel>;
  /** Sales information of a car */
  salesInfo?: Maybe<StockCarSalesInfo>;
  /** field for UK market which delivers SKU */
  stockkeepingunit?: Maybe<Scalars['String']['output']>;
  /** Text used as subtitle. For example "2.0 TFSI quattro Progressiv 200 HP Tiptronic". */
  subtitleText?: Maybe<Scalars['String']['output']>;
  /** field for UK market which delivers tax band for Vehicle Excise Duty */
  taxBandVedCode?: Maybe<Scalars['String']['output']>;
  /** information to describe technical attributes of a car */
  techDataGroups: Array<StockCarTechDataGroup>;
  /** Text used as title. For example "2016 Audi Q3". */
  titleText?: Maybe<Scalars['String']['output']>;
  /** tyre label data */
  tyreLabels: Array<StockCarTyreLabelContainer>;
  /** field that delivers information if vat is reclaimable */
  vatReclaimable: Scalars['Boolean']['output'];
  /** Vehicle identification number of a car. */
  vin?: Maybe<Scalars['String']['output']>;
  /** StockCar warranties with conditions assigned to the car, like 'Neuwagengarantie' until '40000 km' */
  warranties: Array<StockCarWarranty>;
  /** link to the cars detail page */
  weblink?: Maybe<Scalars['String']['output']>;
};


/** information about a Stock-Car */
export type StockCarDynamicAttributeValueArgs = {
  id: Scalars['String']['input'];
};


/** information about a Stock-Car */
export type StockCarImagesArgs = {
  groupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  imageIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Used as container to provide StockCarFeature elements grouped by assigned categories from CDB */
export type StockCarCdbCategory = {
  __typename?: 'StockCarCDBCategory';
  /** list of StockCarFeature elements assigned to this category */
  features: Array<StockCarFeature>;
  /** identifier of CDB category, like 'safety_security_shell.safety_security' */
  id: Scalars['String']['output'];
  /** description of category to be displayed in frontends, like 'Safety and Security' */
  label: Scalars['String']['output'];
};

/** Used as container to group StockCarCDBCategory elements by corresponding parent categories from CDB */
export type StockCarCdbCategoryGroup = {
  __typename?: 'StockCarCDBCategoryGroup';
  /** list of child elements represented as StockCarCDBCategory elements */
  categories: Array<StockCarCdbCategory>;
  /** identifier of CDB category, like 'safety_security_shell' */
  id: Scalars['String']['output'];
  /** description of category to be displayed in frontends, like 'Safety and Security' */
  label: Scalars['String']['output'];
};

/** Element containing an additional CDB attribute with corresponding texts */
export type StockCarCdbItem = {
  __typename?: 'StockCarCDBItem';
  /** identifier of data, like 'body-type' */
  id: Scalars['String']['output'];
  /** list of texts, identified by IDs */
  textInfos: Array<StockCarStringItemWithId>;
  /** corresponding text, like 'sportback' */
  value: Scalars['String']['output'];
};

/** information of a single campaign for stock cars */
export type StockCarCampaign = {
  __typename?: 'StockCarCampaign';
  benefit?: Maybe<Scalars['Float']['output']>;
  end?: Maybe<StockCarDateInfo>;
  frontends: Array<StockCarCampaignFrontend>;
  id: Scalars['String']['output'];
  kind?: Maybe<Scalars['String']['output']>;
  legalEntity?: Maybe<Scalars['String']['output']>;
  market?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  start?: Maybe<StockCarDateInfo>;
  type?: Maybe<Scalars['String']['output']>;
  typeDetail?: Maybe<Scalars['String']['output']>;
};

/** footer for campaign for stock cars */
export type StockCarCampaignFooter = {
  __typename?: 'StockCarCampaignFooter';
  footerText?: Maybe<Scalars['String']['output']>;
  index: Scalars['String']['output'];
};

/** localized frontend information of a single campaign for stock cars */
export type StockCarCampaignFrontend = {
  __typename?: 'StockCarCampaignFrontend';
  bannerBig?: Maybe<Scalars['String']['output']>;
  bannerSmall?: Maybe<Scalars['String']['output']>;
  benefitList?: Maybe<Scalars['String']['output']>;
  benefitText?: Maybe<Scalars['String']['output']>;
  buttonLabel?: Maybe<Scalars['String']['output']>;
  campaignDescription?: Maybe<Scalars['String']['output']>;
  campaignName?: Maybe<Scalars['String']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  disclaimer?: Maybe<Scalars['String']['output']>;
  externalInfoLabel?: Maybe<Scalars['String']['output']>;
  externalInfoUrl?: Maybe<Scalars['String']['output']>;
  filterText?: Maybe<Scalars['String']['output']>;
  footers: Array<StockCarCampaignFooter>;
  headline?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  iconText?: Maybe<Scalars['String']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  infoLayerIcon?: Maybe<Scalars['String']['output']>;
  infoLayerText?: Maybe<Scalars['String']['output']>;
  infoLayerUrl?: Maybe<Scalars['String']['output']>;
  infoLayerUrlText?: Maybe<Scalars['String']['output']>;
  infoText?: Maybe<Scalars['String']['output']>;
  lead?: Maybe<Scalars['String']['output']>;
  leadInfoTextCustomer?: Maybe<Scalars['String']['output']>;
  leadInfoTextDealer?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  micrositeUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** information about the AudiCode of a car */
export type StockCarCarCode = CarCode & {
  __typename?: 'StockCarCarCode';
  /** Country that the `id` is valid for. */
  country?: Maybe<Scalars['String']['output']>;
  /**
   * Unique identifier for the car.
   * Might be AudiCode, MarketingCode etc.
   */
  id: Scalars['String']['output'];
  /** Language that the `id` is valid for. */
  language?: Maybe<Scalars['String']['output']>;
  /** URL behind the Code (AudiCode). Can be used to open the car in a browser. */
  url: Scalars['String']['output'];
};

/** Identifier for a model of a car. Contains necessary Information about the car model */
export type StockCarCarModelIdentifier = CarModelIdentifier & {
  __typename?: 'StockCarCarModelIdentifier';
  /** model base code. Describes the model of the car. */
  code: Scalars['String']['output'];
  /** pr numbers of the car. Sometimes this PrNumbers are more like a marker. For example mark the trimline of the model. */
  extensions?: Maybe<Array<Scalars['String']['output']>>;
  /** model version of the car. */
  version: Scalars['Int']['output'];
  /** The year of the catalogue release. */
  year: Scalars['Int']['output'];
};

/** A specific price. */
export type StockCarCarPrice = CarPrice & {
  __typename?: 'StockCarCarPrice';
  /** Information about the currency */
  currencyDetails?: Maybe<Currency>;
  /** not supported yet */
  formattedValue?: Maybe<Scalars['String']['output']>;
  /** Price as float */
  value: Scalars['Float']['output'];
  /** price as string */
  valueAsText?: Maybe<Scalars['String']['output']>;
};

/** All available prices for a car. */
export type StockCarCarPrices = CarPrices & {
  __typename?: 'StockCarCarPrices';
  /** The total price of the car. */
  total?: Maybe<StockCarCarPrice>;
};

/** Carline information */
export type StockCarCarline = CarlineBase & {
  __typename?: 'StockCarCarline';
  /** ID of the carline. F.ex. a4avant, a3sb, etc. */
  id: Scalars['String']['output'];
  /** Localized name of the carline. F.ex. A4 Avant, A3 Sportback, etc. */
  name: Scalars['String']['output'];
};

export type StockCarCo2Items = {
  __typename?: 'StockCarCo2Items';
  /** Code for combined class text */
  combinedClassText: Scalars['String']['output'];
  /** Code for empty battery class text */
  emptyBatteryClassText?: Maybe<Scalars['String']['output']>;
};

/** Color information for a single car */
export type StockCarColorInfo = {
  __typename?: 'StockCarColorInfo';
  /** exterior color information */
  exteriorColor?: Maybe<StockCarExteriorColor>;
  /** interior color information */
  interiorColor?: Maybe<StockCarInteriorColor>;
  /** top color information */
  topColor?: Maybe<StockCarTopColor>;
};

/** consumption or emission for single engine type */
export type StockCarConsumptionAndEmissionEngineType = {
  __typename?: 'StockCarConsumptionAndEmissionEngineType';
  /** identifier of engine type */
  id: Scalars['String']['output'];
  /** display text of engine type */
  label?: Maybe<Scalars['String']['output']>;
  /** NEDC data for engine type */
  nedc?: Maybe<StockCarConsumptionAndEmissionScope>;
  /** WLTP data for engine type */
  wltp?: Maybe<StockCarConsumptionAndEmissionScope>;
};

/** main structure for consumption and emission data for a stock car */
export type StockCarConsumptionAndEmissionGroup = {
  __typename?: 'StockCarConsumptionAndEmissionGroup';
  /** list of measured engine types */
  engineTypes: Array<StockCarConsumptionAndEmissionEngineType>;
  /** display text */
  label: Scalars['String']['output'];
};

/** visibility of a consumption and emission value */
export enum StockCarConsumptionAndEmissionHighlighting {
  /** showed on detail page */
  Detail = 'DETAIL',
  /** showed in extended view on detail page */
  Extended = 'EXTENDED',
  /** showed on result page */
  Result = 'RESULT'
}

/** information of a single driving cycle */
export type StockCarConsumptionAndEmissionScope = {
  __typename?: 'StockCarConsumptionAndEmissionScope';
  /**
   * name of driving cycle
   * @deprecated not available in most markets an not used in FE. Will be removed in a further step.
   */
  label: Scalars['String']['output'];
  /**
   * link with further information about the driving cycle
   * @deprecated not available in most markets an not used in FE. Will be removed in a further step.
   */
  link: StockCarLabeledUrlValueItem;
  /** list of measured fuels */
  types: Array<StockCarConsumptionAndEmissionType>;
};

/** kind of fuel */
export type StockCarConsumptionAndEmissionType = {
  __typename?: 'StockCarConsumptionAndEmissionType';
  /** identifier of type */
  id: Scalars['String']['output'];
  /** display text for type */
  label: Scalars['String']['output'];
  /** list of measured values */
  values: Array<StockCarConsumptionAndEmissionValue>;
};


/** kind of fuel */
export type StockCarConsumptionAndEmissionTypeValuesArgs = {
  highlighting?: InputMaybe<Array<StockCarConsumptionAndEmissionHighlighting>>;
};

/** single measurement value for consumption and emission */
export type StockCarConsumptionAndEmissionValue = {
  __typename?: 'StockCarConsumptionAndEmissionValue';
  /** visibility information */
  highlighting: Array<StockCarConsumptionAndEmissionHighlighting>;
  /** identifier of value */
  id: Scalars['String']['output'];
  /** label (as display text) */
  label: Scalars['String']['output'];
  /** unit (as display text) */
  unitText: Scalars['String']['output'];
  /** value (as display text) */
  valueText: Scalars['String']['output'];
};

/** Used as a container to provide parseable date information or plain texts, identified by type. */
export type StockCarDateInfo = {
  __typename?: 'StockCarDateInfo';
  /** type of value, can be timestamp or text */
  type?: Maybe<Scalars['String']['output']>;
  /** text representation of a value, outcome defined by type */
  value?: Maybe<Scalars['String']['output']>;
};

/** Information about a dealer group */
export type StockCarDealerGroup = {
  __typename?: 'StockCarDealerGroup';
  /** List of dealers in the group */
  dealers: Array<StockCarDealerGroupDealer>;
  /** group name */
  groupName?: Maybe<Scalars['String']['output']>;
};

/** Information about a dealer in a dealer group */
export type StockCarDealerGroupDealer = {
  __typename?: 'StockCarDealerGroupDealer';
  /** geolocation of the dealer */
  geoLocation?: Maybe<GeoLocation>;
  /** hid of the dealer */
  hid?: Maybe<Scalars['String']['output']>;
  /** id of the dealer */
  id?: Maybe<Scalars['String']['output']>;
  /** investor code of the dealer */
  investorCode?: Maybe<Scalars['String']['output']>;
  /** name of the dealer */
  name?: Maybe<Scalars['String']['output']>;
};

/** Used as a data structure to provide engine related information of a car. */
export type StockCarEngine = {
  __typename?: 'StockCarEngine';
  /** consumption data */
  consumption?: Maybe<StockCarConsumptionAndEmissionGroup>;
  /** emission data */
  emission?: Maybe<StockCarConsumptionAndEmissionGroup>;
  /** Use of unit, value, formattedValue. For example "ccm", "1994", "1994 ccm". */
  engineDisplacement?: Maybe<StockCarFloatValueWithUnitItem>;
  /** Use of code and text. For example "B" and "Benzin". */
  fuel?: Maybe<StockcarCodedTextItem>;
  /** provide engine max power in kW with formattedValue, unit and float value */
  maxPowerKW?: Maybe<StockCarFloatValueWithUnitItem>;
  /** provide engine max power in PS with formattedValue, unit and float value */
  maxPowerPS?: Maybe<StockCarFloatValueWithUnitItem>;
  /** range data (for electrical cars) */
  range?: Maybe<StockCarConsumptionAndEmissionGroup>;
};

/**
 * EnVKV information for a car
 * The EnVKV information must have a specific format based on the currently valid legal provisions. This can be changed and in this case there could be multiple formats available.
 */
export type StockCarEnvkv = StockCarEnvkv2024 | StockCarEnvkvExtended;

/** EnVKV information for a car in new format due to new legal requirements. For further information please see Second Ordinance amending the Car Energy Consumption Labeling Ordinance. Bundesgesetzblatt FNA: 754-17-2. */
export type StockCarEnvkv2024 = {
  __typename?: 'StockCarEnvkv2024';
  /** Information of co2 class for a car */
  co2Class: StockCarCo2Items;
  /** End date for calculation of co2 price */
  co2CostEndYearText: Scalars['String']['output'];
  /** Start date for calculation of co2 price */
  co2CostStartYearText: Scalars['String']['output'];
  /** Informations about the co2 costs for a single car */
  co2Costs: StockCarEnvkv2024Co2CostsItems;
  /** consumption tax */
  consumptionTax: StockCarTextValueWithUnitItem;
  /** creation date of this EnVKV information */
  creationDateText: Scalars['String']['output'];
  /** default mileage */
  defaultMileage: StockCarTextValueWithUnitItem;
  /** Information about the kind of drive, for example "Verbrennungsmotor" */
  driveText: Scalars['String']['output'];
  /** Information about the the energy costs for a single car, like "1.641,20" "EUR/Jahr" */
  energyCosts: StockCarTextValueWithUnitItem;
  /** Reference year for fuel tariff */
  fuelTariffReferenceYearText: Scalars['String']['output'];
  /** URL for consumption and emission label in PDF format */
  labelUrlPDF: Scalars['String']['output'];
  /** URL for consumption and emission label in SVG format */
  labelUrlSVG: Scalars['String']['output'];
  /** Code for cartype */
  legalCartypeCode: Scalars['String']['output'];
  /** information for primary fuel */
  primaryFuel: StockCarEnvkv2024Fuel;
  /** information for secondary fuel */
  secondaryFuel?: Maybe<StockCarEnvkv2024Fuel>;
};

/** Information about co2 costs and tariff for a single estimation */
export type StockCarEnvkv2024Co2Costs = {
  __typename?: 'StockCarEnvkv2024Co2Costs';
  /** total co2 costs for the next 10 years with 15.000 km each, like "975,00" "EUR" */
  costs: StockCarTextValueWithUnitItem;
  /** tariff for calculation of the total co2 costs, like "50" "EUR/t" */
  tariff: StockCarTextValueWithUnitItem;
};

/** Co2 costs infomormation for different price estimations */
export type StockCarEnvkv2024Co2CostsItems = {
  __typename?: 'StockCarEnvkv2024Co2CostsItems';
  /** co2 costs for high estimation */
  high: StockCarEnvkv2024Co2Costs;
  /** co2 costs for low estimation */
  low: StockCarEnvkv2024Co2Costs;
  /** co2 costs for medium estimation */
  medium: StockCarEnvkv2024Co2Costs;
};

/** fuel information for EnVKV2024 */
export type StockCarEnvkv2024Fuel = {
  __typename?: 'StockCarEnvkv2024Fuel';
  /** consumption unit as display text */
  consumptionUnitText: Scalars['String']['output'];
  /** identifier for fuel */
  id: Scalars['String']['output'];
  /** price value for one unit of the fuel as display text */
  tariffText: Scalars['String']['output'];
  /** information about the tariff and unit, like "EUR/l" */
  tariffUnitText: Scalars['String']['output'];
  /** name of fuel */
  typeText: Scalars['String']['output'];
  /** unit of fuel as display text */
  unitText: Scalars['String']['output'];
};

/** EnVKV information for a car (format before the amendment in 2024) */
export type StockCarEnvkvExtended = {
  __typename?: 'StockCarEnvkvExtended';
  /** consumption tax */
  consumptionTax: StockCarLabeledTextValueWithUnitItem;
  /** creation date of this EnVKV information */
  creationDate: StockCarLabeledDateInfo;
  /** default mileage */
  defaultMileage: StockCarLabeledTextValueWithUnitItem;
  /** efficiency class as display text */
  efficiencyClassText?: Maybe<Scalars['String']['output']>;
  /** information for primary fuel */
  primaryFuel: StockCarEnvkvExtendedFuel;
  /** information for secondary fuel */
  secondaryFuel?: Maybe<StockCarEnvkvExtendedFuel>;
};

/** fuel information for EnVKV extended */
export type StockCarEnvkvExtendedFuel = {
  __typename?: 'StockCarEnvkvExtendedFuel';
  /** consumption unit as display text */
  consumptionUnitText: Scalars['String']['output'];
  /** fuel costs */
  costs: StockCarLabeledTextValueWithUnitItem;
  /** identifier for fuel */
  id: Scalars['String']['output'];
  /** price value for one unit of the fuel as display text */
  tariffText: Scalars['String']['output'];
  /** name of fuel */
  typeText: Scalars['String']['output'];
  /** unit of fuel as display text */
  unitText: Scalars['String']['output'];
};

/** extended information about a stockcar */
export type StockCarExtended = {
  __typename?: 'StockCarExtended';
  /** similar cars in new car stock and used car stock with this stockcar as reference */
  similarCars?: Maybe<SimilarCarsStock>;
  /** basic data for a stockcar */
  stockCar: StockCar;
};

/** Information about exterior color for a single car */
export type StockCarExteriorColor = {
  __typename?: 'StockCarExteriorColor';
  /** generic color information for all cars, like code:"07", text:"Black" */
  baseColorInfo?: Maybe<StockcarCodedTextItem>;
  /** description of specific color, like code:"A2", text:"Brilliant Black" */
  colorInfo?: Maybe<StockcarCodedTextItem>;
  /** description of color type, like 'Exterior' */
  label?: Maybe<Scalars['String']['output']>;
};

/** Interface describing equipment features in StockCar context. */
export type StockCarFeature = {
  /** enum of equipment feature; either series or special equipment */
  featureType: StockCarFeatureType;
  /** list of categories grouping mapped generic equipments */
  genericEquipmentCategories: Array<StockCarGenericEquipmentCategory>;
  /** list of generic equipments mapped to current feature */
  genericEquipments: Array<StockCarGenericEquipment>;
  /** list of weblinks to image resources, identified by IDs */
  imageResources: Array<StockCarStringItemWithId>;
  /** PrNumber codes */
  prNumber: StockCarPrNumber;
  /** text describing feature, like 'Anschlussgarantie 3 Jahre, max. 100.000 km' */
  text: Scalars['String']['output'];
  /** all texts from different sources assigned to current feature, identified by unique fields */
  textInfos: StockCarFeatureTextInfo;
};

/** Implementation of StockCarFeature. Used to describe PrNumbers which are not Packages. Extends interface by field 'packages'. */
export type StockCarFeatureEquipment = StockCarFeature & {
  __typename?: 'StockCarFeatureEquipment';
  /** type of equipment feature; either series or special equipment */
  featureType: StockCarFeatureType;
  /** list of categories grouping mapped generic equipments */
  genericEquipmentCategories: Array<StockCarGenericEquipmentCategory>;
  /** list of generic equipments mapped to current feature */
  genericEquipments: Array<StockCarGenericEquipment>;
  /** list of weblinks to image resources, identified by IDs */
  imageResources: Array<StockCarStringItemWithId>;
  /** list of StockCarFeaturePackage containing this feature */
  packages: Array<StockCarFeaturePackage>;
  /** PrNumber codes */
  prNumber: StockCarPrNumber;
  /** text describing feature, like 'Anschlussgarantie 3 Jahre, max. 100.000 km' */
  text: Scalars['String']['output'];
  /** all texts from different sources assigned to current feature, identified by unique fields */
  textInfos: StockCarFeatureTextInfo;
};

/** Implementation of StockCarFeature. Used to describe PrNumbers which are Packages. Extends interface by field 'features'. */
export type StockCarFeaturePackage = StockCarFeature & {
  __typename?: 'StockCarFeaturePackage';
  /** list of StockCarFeatureEquipment which are part of this package */
  equipments: Array<StockCarFeatureEquipment>;
  /** type of equipment feature; either series or special equipment */
  featureType: StockCarFeatureType;
  /** list of categories grouping mapped generic equipments */
  genericEquipmentCategories: Array<StockCarGenericEquipmentCategory>;
  /** list of generic equipments mapped to current feature */
  genericEquipments: Array<StockCarGenericEquipment>;
  /** list of weblinks to image resources, identified by IDs */
  imageResources: Array<StockCarStringItemWithId>;
  /** PrNumber codes */
  prNumber: StockCarPrNumber;
  /** text describing feature, like 'Anschlussgarantie 3 Jahre, max. 100.000 km' */
  text: Scalars['String']['output'];
  /** all texts from different sources assigned to current feature, identified by unique fields */
  textInfos: StockCarFeatureTextInfo;
};

/** Used as a container to provide texts from different sources assigned to a feature, identified by their original IDs. */
export type StockCarFeatureTextInfo = {
  __typename?: 'StockCarFeatureTextInfo';
  /** list of benefit texts without order from source multimedia DB (only Audi) */
  benefits: Array<Scalars['String']['output']>;
  /** details text from source multimedia DB (only Audi) */
  details?: Maybe<Scalars['String']['output']>;
  /** intro text from source multimedia DB (only Audi) */
  intro?: Maybe<Scalars['String']['output']>;
  /** text from source multimedia DB (only Audi) */
  name?: Maybe<Scalars['String']['output']>;
  /** list of remark texts without order from source multimedia DB (only Audi) */
  remarks: Array<Scalars['String']['output']>;
  /** teaser text from source multimedia DB (only Audi) */
  teaser?: Maybe<Scalars['String']['output']>;
};

/** Enum describing type of equipment feature, like 'SPECIAL' or 'SERIES' */
export enum StockCarFeatureType {
  Series = 'SERIES',
  Special = 'SPECIAL'
}

/** Used as a container to provide data in float format as a raw and formatted value */
export type StockCarFloatValueItem = {
  __typename?: 'StockCarFloatValueItem';
  /** formatted value for display */
  formatted: Scalars['String']['output'];
  /** float value */
  number: Scalars['Float']['output'];
};

/** Used as a container to provide data in float format as a raw and formatted value with a unit. */
export type StockCarFloatValueWithUnitItem = {
  __typename?: 'StockCarFloatValueWithUnitItem';
  /** formatted unit display text */
  unitText?: Maybe<Scalars['String']['output']>;
  /** formatted value for display */
  value: StockCarFloatValueItem;
};

/** information of a single function on demand (FoD) (for stock cars) */
export type StockCarFunctionOnDemand = {
  __typename?: 'StockCarFunctionOnDemand';
  /** machine readable category of the FoD */
  categoryCode?: Maybe<Scalars['String']['output']>;
  /** expiration date of the FoD, after which it is no longer active */
  dateExpiration?: Maybe<StockCarDateInfo>;
  /** signing date of the FoD */
  dateSigning?: Maybe<StockCarDateInfo>;
  /** start date of the FoD - starting from which it becomes active */
  dateStart?: Maybe<StockCarDateInfo>;
  /** human readable description of the FoD */
  description?: Maybe<Scalars['String']['output']>;
  /** timespan between start and expiration date of the FoD */
  duration?: Maybe<StockCarRawIntValueWithUnitItem>;
  /** ID of holder of FoD. */
  holderId?: Maybe<Scalars['String']['output']>;
  /** end mileage of FoD. defaults to 999999. */
  mileageEnd?: Maybe<StockCarRawIntValueWithUnitItem>;
  /** max mileage of FoD. defaults to 0. */
  mileageMax?: Maybe<StockCarRawIntValueWithUnitItem>;
  /** start mileage of FoD. defaults to 0. */
  mileageStart?: Maybe<StockCarRawIntValueWithUnitItem>;
  /** total mileage of FoD. defaults to 0. */
  mileageTotal?: Maybe<StockCarRawIntValueWithUnitItem>;
  /** origin system of the FoD */
  originCode?: Maybe<Scalars['String']['output']>;
  /** whether the FoD is preliminary */
  preliminary?: Maybe<Scalars['Boolean']['output']>;
  /** machine-readable indicator of whether the FoD is active or not */
  statusCode?: Maybe<Scalars['String']['output']>;
  /** type of the FoD */
  type: StockCarFunctionOnDemandType;
};

/** type of a function on demand (FoD) */
export enum StockCarFunctionOnDemandType {
  /** FoD type "FoDLifetime" */
  FodLifetime = 'FOD_LIFETIME',
  /** FoD type "FoDRental" */
  FodRental = 'FOD_RENTAL',
  /** FoD type "ServiceAndWearOEM" */
  ServiceAndWearOem = 'SERVICE_AND_WEAR_OEM',
  /** FoD type "ServiceAndWearVWFS" */
  ServiceAndWearVwfs = 'SERVICE_AND_WEAR_VWFS',
  /** FoD type "ServiceContract" */
  ServiceContract = 'SERVICE_CONTRACT'
}

/** Used as a container to provide generic equipment items. Can also be used for 3rd party vehicles. */
export type StockCarGenericEquipment = {
  __typename?: 'StockCarGenericEquipment';
  /** list of mapped generic equipment categories for grouping purposes */
  categories: Array<StockCarGenericEquipmentCategory>;
  /** coded identifier of an equipment item, like 'AL' */
  code: Scalars['String']['output'];
  /** list of mapped StockCarFeatures, extending this generic information */
  features: Array<StockCarFeature>;
  /** textual description of an equipment item, like 'Lederausstattung' */
  text: Scalars['String']['output'];
};

/** Used as a container to provide categories for generic equipment items. */
export type StockCarGenericEquipmentCategory = {
  __typename?: 'StockCarGenericEquipmentCategory';
  /** coded identifier of an equipment item, like '6' */
  code: Scalars['String']['output'];
  /** list of generic equipments belonging to this category */
  equipments: Array<StockCarGenericEquipment>;
  /** textual description of an equipment item, like 'Seats' */
  text: Scalars['String']['output'];
};

export type StockCarIdentifierInput = {
  /** specify the id of one single vehicle */
  id: Scalars['String']['input'];
  /** specify the stock which contains the vehicle */
  stockIdentifier: StockIdentifierInput;
};

/** single image of a stock car */
export type StockCarImage = {
  __typename?: 'StockCarImage';
  /** height of image in pixels */
  height?: Maybe<Scalars['Int']['output']>;
  /** unique id of image in a stock car */
  id: StockCarImageIdentifier;
  /** MIME type of image e.g. 'image/png' */
  mimeType?: Maybe<Scalars['String']['output']>;
  /** kind of image: e.g. 'render', 'photo' */
  type?: Maybe<Scalars['String']['output']>;
  /** url of image in a stock car */
  url: Scalars['String']['output'];
  /** width of image in pixels */
  width?: Maybe<Scalars['Int']['output']>;
};

/** unique id of image in a stock car */
export type StockCarImageIdentifier = {
  __typename?: 'StockCarImageIdentifier';
  /** group id of image in a stock car */
  group: Scalars['String']['output'];
  /** image id inside a group in a stock car */
  image: Scalars['String']['output'];
};

/** Information about interior color for a single car */
export type StockCarInteriorColor = {
  __typename?: 'StockCarInteriorColor';
  /** generic color information for all cars, like code:"07", text:"Black" */
  baseColorInfo?: Maybe<StockcarCodedTextItem>;
  /** description of specific color, like code:"YA", text:"Myth-Black" */
  colorInfo?: Maybe<StockcarCodedTextItem>;
  /** description of color type, like 'Innenfarbe' */
  label?: Maybe<Scalars['String']['output']>;
};

/** date value with label */
export type StockCarLabeledDateInfo = {
  __typename?: 'StockCarLabeledDateInfo';
  /** date value */
  dateInfo: StockCarDateInfo;
  /** label (as display text) */
  label: Scalars['String']['output'];
};

/** (display) text value with label und unit */
export type StockCarLabeledTextValueWithUnitItem = {
  __typename?: 'StockCarLabeledTextValueWithUnitItem';
  /** label (as display text) */
  label: Scalars['String']['output'];
  /** value (as display text) */
  text: Scalars['String']['output'];
  /** unit (as display text) */
  unitText: Scalars['String']['output'];
};

/** url with display text */
export type StockCarLabeledUrlValueItem = {
  __typename?: 'StockCarLabeledUrlValueItem';
  /** label (as display text) */
  label: Scalars['String']['output'];
  /** url */
  url: Scalars['String']['output'];
};

/** union of different implementations for manufacturer specific items */
export type StockCarManufacturer = StockCarManufacturer3rdParty | StockCarManufacturerAudi;

/** implementations of manufacturer specific for not group vehicles, like 'BMW', 'Ford' */
export type StockCarManufacturer3rdParty = {
  __typename?: 'StockCarManufacturer3rdParty';
  /** Name of 3rd party manufacturer */
  name: Scalars['String']['output'];
};

/** implementations of manufacturer specific items for brand Audi */
export type StockCarManufacturerAudi = {
  __typename?: 'StockCarManufacturerAudi';
  /** list of StockCarFeature elements, grouped by CDB information */
  cdbCategories: Array<StockCarCdbCategoryGroup>;
  /** list of additional attributes of a car provided by CDB */
  cdbItems: Array<StockCarCdbItem>;
};


/** implementations of manufacturer specific items for brand Audi */
export type StockCarManufacturerAudiCdbItemsArgs = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Model information */
export type StockCarModel = CarModelBase & {
  __typename?: 'StockCarModel';
  /** Identifier of the model. */
  id: StockCarCarModelIdentifier;
  /** Name of the model */
  name?: Maybe<Scalars['String']['output']>;
  /** year model actually sold */
  salesModelyear?: Maybe<Scalars['Int']['output']>;
};

/** Used as a container to describe the 7 digit PrCode known from Carport. */
export type StockCarPrNumber = {
  __typename?: 'StockCarPrNumber';
  /** 1digit equipment class origin code, like 'L' */
  class: Scalars['String']['output'];
  /** 3digit option code, like 'AV1' */
  code: Scalars['String']['output'];
  /** 3digit family code, like 'LRV', can be empty in case of package */
  family: Scalars['String']['output'];
};

/** Used as a container to describe a 6 digit PrCode */
export type StockCarPrNumberWithoutClass = {
  __typename?: 'StockCarPrNumberWithoutClass';
  /** 3 digit option code, like 'AV1' */
  code: Scalars['String']['output'];
  /** 3 digit family code, like 'LRV', can be empty in case of package */
  family: Scalars['String']['output'];
};

/** Type to describe disclaimer information for price items (atm only for financeRate). */
export type StockCarPriceDisclaimer = {
  __typename?: 'StockCarPriceDisclaimer';
  /** Disclaimer id like "Product". */
  id: Scalars['String']['output'];
  /** Disclaimer legal entity key like "companyLeasingProvider". */
  legalEntityKey: Scalars['String']['output'];
  /** Disclaimer text. */
  text: Scalars['String']['output'];
};

export type StockCarQualityLabel = {
  __typename?: 'StockCarQualityLabel';
  /** hyperlink to image resource, like 'https://vtpapi.audi.de/vtp_base/img/audi/highlights-plus-warranty_60x45px.png' */
  imageURL?: Maybe<Scalars['String']['output']>;
  /** corresponding display text, like 'Audi Gebrauchtwagen :plus' */
  label: Scalars['String']['output'];
  /** additional legal information, like 'Dealers' */
  legalEntity?: Maybe<Scalars['String']['output']>;
  /** shorthand identifier of quality label, like 'GWPLUS' */
  name: Scalars['String']['output'];
};

/** raw integer value (without formatted value) and the corresponding unit */
export type StockCarRawIntValueWithUnitItem = {
  __typename?: 'StockCarRawIntValueWithUnitItem';
  /** unit (as display text) */
  unitText: Scalars['String']['output'];
  /** raw integer value */
  value: Scalars['Int']['output'];
};

/** Used as a data structure to provide sales info for different markets at one place */
export type StockCarSalesInfo = {
  __typename?: 'StockCarSalesInfo';
  /** Information about available status of a car; like value:"Immediately", type:"text" */
  availableFromDateInfo?: Maybe<StockCarDateInfo>;
  /** Information about e-commerce status of a car. */
  isBuyOnline?: Maybe<Scalars['Boolean']['output']>;
  /** Information about offer status of a car; like value:"2023-06-24T16:11:17+02:00", type:"timestamp" */
  offerDateInfo?: Maybe<StockCarDateInfo>;
  /** Information about order status like "Intransit From Factory". */
  orderStatusText?: Maybe<Scalars['String']['output']>;
  /** Information about the order status like "30". */
  physicalOrderStatus?: Maybe<Scalars['String']['output']>;
  /** Information about order type like "SHIP - Deliverable Inventory Order". */
  saleOrderTypeText?: Maybe<Scalars['String']['output']>;
};

/** Response of stockcar search for passed search parameter */
export type StockCarSearch = {
  __typename?: 'StockCarSearch';
  /** number of found cars */
  resultNumber: Scalars['Int']['output'];
  /** found cars */
  results: StockCarSearchResults;
  /** data for further search actions */
  search: StockCarSearchSearch;
};

/** All possible stockcar search configurations for a specific market */
export type StockCarSearchConfiguration = {
  __typename?: 'StockCarSearchConfiguration';
  /** meta-data for all available search options */
  criteria: Array<StockCarSearchCriterionConfiguration>;
  /** search groups */
  searchGroup: SearchGroupNode;
  /** meta-data for all available sort options */
  sortableItems: Array<StockCarSearchSortableItemsConfiguration>;
  /** meta-data for all available special search options (with unrestricted 'values') */
  specialCriteria: Array<StockCarSearchSpecialCriterionConfiguration>;
};


/** All possible stockcar search configurations for a specific market */
export type StockCarSearchConfigurationSearchGroupArgs = {
  searchGroup: Scalars['String']['input'];
};

/** Possible and Selected search criteria for a specific market with id and count for matching vehicle */
export type StockCarSearchCriterion = {
  __typename?: 'StockCarSearchCriterion';
  /** Unique identifier for a search option */
  id: Scalars['StockCarSearchCriterionId']['output'];
  /** still available items of search criteria */
  possibleItems: Array<StockCarSearchCriterionItem>;
  /** selected items of search criteria */
  selectedItems: Array<StockCarSearchCriterionItem>;
};

/** All possible search criteria for a specific market */
export type StockCarSearchCriterionConfiguration = {
  __typename?: 'StockCarSearchCriterionConfiguration';
  /** Unique identifier for a search option */
  id: Scalars['StockCarSearchCriterionId']['output'];
  /** meta-data of possible items inside search criteria */
  items: Array<StockCarSearchCriterionItemConfiguration>;
  /** display text for search critera */
  text: Scalars['String']['output'];
  /** display text for unit of search criteria if set */
  unitText?: Maybe<Scalars['String']['output']>;
};

/** ID and number of matches for a search criterion item */
export type StockCarSearchCriterionItem = {
  __typename?: 'StockCarSearchCriterionItem';
  /** Unique identifier for an item in a search criteria */
  id: Scalars['StockCarSearchCriterionItemId']['output'];
  /** number of found cars matching item */
  number: Scalars['Int']['output'];
};

/** All items belonging to a specific search criteria */
export type StockCarSearchCriterionItemConfiguration = {
  __typename?: 'StockCarSearchCriterionItemConfiguration';
  /** Unique identifier for an item in a search criteria */
  id: Scalars['StockCarSearchCriterionItemId']['output'];
  /** display text of item */
  text: Scalars['String']['output'];
};

/** new input */
export type StockCarSearchParameterCriterionInput = {
  /** Unique identifier for a search option */
  id: Scalars['StockCarSearchCriterionId']['input'];
  /** Unique identifier for an item in a search criteria */
  items: Array<Scalars['StockCarSearchCriterionItemId']['input']>;
};

/** parameter for geo search of stock cars */
export type StockCarSearchParameterGeoCriterionInput = {
  /** latitude of geo position (center of search circle) */
  latitude: Scalars['Float']['input'];
  /** longitude of geo position (center of search circle) */
  longitude: Scalars['Float']['input'];
  /** maximum distance between passed geo position (latitude and longitude) and geo position of a found car (radius of search circle) */
  maxDistance: Scalars['Float']['input'];
};

/** All input parameters that can be handed over for the car search */
export type StockCarSearchParameterInput = {
  /** selected search criteria */
  criteria: Array<StockCarSearchParameterCriterionInput>;
  /** parameter for geographic proximity search */
  geo?: InputMaybe<StockCarSearchParameterGeoCriterionInput>;
  /** parameter for paging */
  paging?: InputMaybe<StockCarSearchParameterPagingInput>;
  /** parameter for sorting */
  sort?: InputMaybe<StockCarSearchParameterSortInput>;
  /** selected special search criteria to filter the search by */
  specialCriteria?: InputMaybe<Array<StockCarSearchParameterSpecialCriterionInput>>;
};

/** Input parameter for the paging of the stockcar search results */
export type StockCarSearchParameterPagingInput = {
  /** limitation of results */
  limit: Scalars['Int']['input'];
  /** offset set (first element) */
  offset: Scalars['Int']['input'];
};

/** Input parameter to sort stockcar search */
export type StockCarSearchParameterSortInput = {
  /** selected sort direction */
  direction: StockCarSearchSortDirection;
  /** Unique identifier for a sort option */
  id: Scalars['StockCarSearchSortId']['input'];
};

/** information of a single special search criterion for stock cars */
export type StockCarSearchParameterSpecialCriterionInput = {
  /**
   * ID of the special search criteria.
   *
   * Must match an ID returned from stockCarSearchConfiguration.specialCriteria
   */
  id: Scalars['StockCarSearchSpecialCriterionId']['input'];
  /**
   * Values of the search criteria.
   *
   * Any string is acceptable, unlike normal criteria
   */
  items: Array<Scalars['String']['input']>;
};

/** The results for a search with specific search parameters and market */
export type StockCarSearchResults = {
  __typename?: 'StockCarSearchResults';
  /** found cars matching configured search */
  cars: Array<StockCarSearchResultsCar>;
  /** applied paging parameter */
  paging: StockCarSearchResultsPaging;
  /** applied sort parameter */
  sort: StockCarSearchResultsSort;
};

/** Found cars as results of a car search with specific parameters and market */
export type StockCarSearchResultsCar = {
  __typename?: 'StockCarSearchResultsCar';
  /** used distance to location of car (if proximity search applied) */
  geoDistance?: Maybe<StockCarFloatValueWithUnitItem>;
  /** data of found car */
  stockCar: StockCar;
};

/** The paging options for the results of a car search that was handed over with the parameters and market */
export type StockCarSearchResultsPaging = {
  __typename?: 'StockCarSearchResultsPaging';
  /** limit set */
  limit: Scalars['Int']['output'];
  /** offset set (first element) */
  offset: Scalars['Int']['output'];
};

/** The sorting options for the results of a car search that was handed over with the parameters and market */
export type StockCarSearchResultsSort = {
  __typename?: 'StockCarSearchResultsSort';
  /** selected sort direction */
  direction: StockCarSearchSortDirection;
  /** Unique identifier for a sort option */
  id: Scalars['StockCarSearchSortId']['output'];
};

/** List of all possible search criterias */
export type StockCarSearchSearch = {
  __typename?: 'StockCarSearchSearch';
  /** list of search criteria */
  criteria: Array<StockCarSearchCriterion>;
};

/** new enum */
export enum StockCarSearchSortDirection {
  /** ascending sort direction */
  Asc = 'ASC',
  /** descending sort direction */
  Desc = 'DESC'
}

/** All sortable Items for a market and the ascText and descText */
export type StockCarSearchSortableItemsConfiguration = {
  __typename?: 'StockCarSearchSortableItemsConfiguration';
  /** display text on ascending sort */
  ascText: Scalars['String']['output'];
  /** display text on descending sort */
  descText: Scalars['String']['output'];
  /** Unique identifier for a sort option */
  id: Scalars['StockCarSearchSortId']['output'];
};

/** information of a single special search criterion for stock cars */
export type StockCarSearchSpecialCriterionConfiguration = {
  __typename?: 'StockCarSearchSpecialCriterionConfiguration';
  /** Unique identifier for a search option */
  id: Scalars['StockCarSearchSpecialCriterionId']['output'];
};

/** Basic type to display corresponding id/value strings in StockCar */
export type StockCarStringItemWithId = {
  __typename?: 'StockCarStringItemWithId';
  /** identifier of data */
  id: Scalars['String']['output'];
  /** corresponding text */
  value: Scalars['String']['output'];
};

export type StockCarSubServiceStatusDependency = {
  __typename?: 'StockCarSubServiceStatusDependency';
  health: OneGraphServiceHealth;
  name: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

/** Used as a container to provide text based technical information identified by an ID. */
export type StockCarTechData = {
  __typename?: 'StockCarTechData';
  /** identifier of data, like 'acceleration' */
  id: Scalars['String']['output'];
  /** text to display as description, like 'Acceleration (0 - 60 mph)' */
  label: Scalars['String']['output'];
  /** text based information about technical data, like text:'6.6 sec seconds' */
  text: Scalars['String']['output'];
};

/** Used as a container to provide technical information as StockCarTechData grouped by IDs. */
export type StockCarTechDataGroup = {
  __typename?: 'StockCarTechDataGroup';
  /** identifier of data set, like 'engine' */
  id: Scalars['String']['output'];
  /** text to display as description of id, like 'Engineering | Performance' */
  label: Scalars['String']['output'];
  /** grouped StockCarTechData */
  techDataList: Array<StockCarTechData>;
};

/** (display) text value with unit */
export type StockCarTextValueWithUnitItem = {
  __typename?: 'StockCarTextValueWithUnitItem';
  /** value (as display text) */
  text: Scalars['String']['output'];
  /** unit (as display text) */
  unitText: Scalars['String']['output'];
};

/** Information about color for the top of a single car */
export type StockCarTopColor = {
  __typename?: 'StockCarTopColor';
  /** description of specific color, like code:"A2", text:"Brilliant Black" */
  colorInfo?: Maybe<StockcarCodedTextItem>;
  /** description of color type, like 'Contrasting (e.g. Roof)' */
  label?: Maybe<Scalars['String']['output']>;
};

/** Information about a single tyre label */
export type StockCarTyreLabel = {
  __typename?: 'StockCarTyreLabel';
  /** The eprel url */
  eprelUrl: Scalars['String']['output'];
  /** The id of the label */
  id: Scalars['String']['output'];
  /** The product sheet url */
  productSheetUrl: Scalars['String']['output'];
  /** Size designation information of a tyre label */
  sizeDesignation: StockCarTyreLabelSizeDesignation;
  /** The trade name */
  tradeName: Scalars['String']['output'];
  /** The tyre label url */
  tyreLabelUrl: Scalars['String']['output'];
  /** The tyre type identifier */
  tyreTypeIdentifier: Scalars['String']['output'];
};

/** Container for tyre label data */
export type StockCarTyreLabelContainer = {
  __typename?: 'StockCarTyreLabelContainer';
  /** tyre label Information */
  labels: Array<StockCarTyreLabel>;
  /** The pr number the tyre label applies to */
  prNumber: StockCarPrNumberWithoutClass;
};

/** Size designation information of a tyre label */
export type StockCarTyreLabelSizeDesignation = {
  __typename?: 'StockCarTyreLabelSizeDesignation';
  /** The load capacity index */
  loadCapacityIndex: Scalars['String']['output'];
  /** The speed category symbol */
  speedCategorySymbol: Scalars['String']['output'];
  /** The tyre size designation */
  tyreSizeDesignation: Scalars['String']['output'];
};

export type StockCarWarranty = {
  __typename?: 'StockCarWarranty';
  /** date string with pattern 'YYYY-MM-DD', like '2025-08-06' */
  dateEnd?: Maybe<Scalars['String']['output']>;
  /** date string with pattern 'YYYY-MM-DD', like '2022-08-07' */
  dateStart?: Maybe<Scalars['String']['output']>;
  /** additional information to be displayed, like 'Garantie vorhanden. Für weitere Informationen wenden Sie sich bitte an den Anbieter des Fahrzeugs' */
  dealerCondition?: Maybe<Scalars['String']['output']>;
  /** unit code for distanceValue, like 'Kilometer' */
  distanceUnitCode?: Maybe<Scalars['String']['output']>;
  /** unit display text for distanceValue, like 'km' */
  distanceUnitText?: Maybe<Scalars['String']['output']>;
  /** number, like '40000' */
  distanceValue?: Maybe<Scalars['Int']['output']>;
  /** hyperlink to document resource, like 'https://www.audi.de/dam/nemo/customer-area/warranty-guarantees/pdf/audi_garantie.pdf' */
  documentURL?: Maybe<Scalars['String']['output']>;
  /** unit code for durationValue, like 'Month' */
  durationUnitCode?: Maybe<Scalars['String']['output']>;
  /** unit display text for durationValue, like 'Monate' */
  durationUnitText?: Maybe<Scalars['String']['output']>;
  /** number, like '24' */
  durationValue?: Maybe<Scalars['Int']['output']>;
  /** corresponding display text, like 'Anschlussgarantie' */
  label: Scalars['String']['output'];
  /** additional legal information, like 'volkswagenAG' */
  legalEntity?: Maybe<Scalars['String']['output']>;
  /** shorthand identifier of quality label, like 'asg' */
  name: Scalars['String']['output'];
  /** free text, set by dealer, like 'Marderschutz' */
  productName?: Maybe<Scalars['String']['output']>;
  /** display order priority, like '15' */
  sortOrder: Scalars['Int']['output'];
};

export enum StockCarsType {
  /** Cars in stock that are new */
  New = 'NEW',
  /** Cars in stock that are used */
  Used = 'USED'
}

/** combinations for select "StockCarsType" */
export enum StockCarsTypeCombinationInput {
  /** only new cars stock is used / affected */
  New = 'NEW',
  /** new and used cars stock are used / affected */
  NewAndUsed = 'NEW_AND_USED',
  /** new or used cars stock are used / affected */
  NewOrUsed = 'NEW_OR_USED',
  /** only used cars stock is used / affected */
  Used = 'USED'
}

export type StockIdentifierInput = {
  /** specify the market of the stocks */
  marketIdentifier: MarketIdentifierInput;
  /** The stock type where the structure should be taken from */
  stockCarsType: StockCarsType;
};

/** Used as a container to provide coded data with a text and vise versa */
export type StockcarCodedTextItem = {
  __typename?: 'StockcarCodedTextItem';
  /** Code representation of a value, used and provided by different systems. */
  code: Scalars['String']['output'];
  /** Text value */
  text: Scalars['String']['output'];
};
