import * as React from 'react';
import { Text } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { OptionsPrice } from '../prices/OptionsPrice';
import { DangerousElement } from '../../DangerousElement';
import { FootnoteReference } from '../../footnotes/components/FootnoteReference';
import { FinancingWrapper } from '../FinancingWrapper';
import { usePriceLabel } from '../../../hooks/text/usePriceLabel';
export const PriceInformationSpain = ({ vehicle, fullVehicle, trackLayerLinkClick, trackFinInfoLayerClose, configureFinanceComponents, }) => {
    const vehicleBasic = vehicle || fullVehicle.basic;
    const retailPrice = vehicleBasic.typedPrices.find((typedPrice) => {
        return typedPrice.type === 'retail';
    });
    const carType = vehicle.type === 'N' ? 'new' : 'used';
    const retailLabel = usePriceLabel(retailPrice, configureFinanceComponents.hideActionPriceLabel);
    const regularPrice = vehicleBasic.typedPrices.find((typedPrice) => {
        return typedPrice.type === 'regular';
    });
    const regularPriceLabel = useI18n({
        defaultMessage: '',
        id: 'nemo.ui.sc.result.regularprice',
    });
    const netCreditAmount = vehicleBasic.typedPrices.find((typedPrice) => {
        return typedPrice.type === 'netcreditamount';
    });
    const netCreditAmountLabel = useI18n({
        defaultMessage: '',
        id: 'nemo.ui.sc.tiles.es.netcreditamount',
    });
    const bonusFinancing = vehicleBasic.typedPrices.find((typedPrice) => {
        return typedPrice.type === 'bonusfinancing';
    });
    const bonusFinancingValue = bonusFinancing === null || bonusFinancing === void 0 ? void 0 : bonusFinancing.formatted;
    const bonusFinancingLabel = useI18n({
        defaultMessage: '',
        id: 'nemo.ui.sc.tiles.es.bonusfinancing',
    }).replace('${bonusfinancing}', `<b>${bonusFinancingValue}</b>`);
    const hasReclaimableVat = vehicleBasic === null || vehicleBasic === void 0 ? void 0 : vehicleBasic.vatReclaimable;
    const reclaimableVatLabel = useI18n({
        id: 'nemo.ui.sc.prices.reclaimablevat',
        defaultMessage: '',
    });
    return (React.createElement(React.Fragment, null,
        retailPrice && (React.createElement(Text, { variant: "copy1", "data-testid": "retailPriceLabel", as: "p", spaceStackEnd: "m" },
            retailLabel,
            "\u00A0",
            React.createElement(Text, { variant: "copy1", "data-testid": "retailPrice", weight: "bold", as: "span" }, retailPrice === null || retailPrice === void 0 ? void 0 : retailPrice.formatted),
            React.createElement(FootnoteReference, { refId: `fn_retail_price_stockcar_${carType}` }),
            hasReclaimableVat && reclaimableVatLabel && (React.createElement(Text, { variant: "copy1" }, reclaimableVatLabel)))),
        netCreditAmount && (React.createElement(Text, { variant: "copy1", "data-testid": "netCreditAmountLabel", as: "p", spaceStackEnd: "xs" },
            netCreditAmountLabel,
            "\u00A0",
            React.createElement(Text, { variant: "copy1", "data-testid": "netCreditAmount", weight: "bold", as: "span" }, netCreditAmount === null || netCreditAmount === void 0 ? void 0 : netCreditAmount.formatted),
            React.createElement(FootnoteReference, { refId: "fn_net_credit_amount" }))),
        bonusFinancing && (React.createElement(Text, { variant: "copy2", "data-testid": "bonusFinancingLabel", as: "p", spaceStackEnd: "xxs" },
            React.createElement(DangerousElement, { html: bonusFinancingLabel, "data-testid": "bonusFinancing", as: "span" }))),
        React.createElement(FinancingWrapper, { vehicle: vehicle, fullVehicle: fullVehicle, trackLayerLinkClick: trackLayerLinkClick, trackFinInfoLayerClose: trackFinInfoLayerClose, configureFinanceComponents: configureFinanceComponents }),
        regularPrice && (React.createElement(Text, { variant: "copy2", "data-testid": "regularPrice", as: "p", spaceStackStart: "xl", spaceStackEnd: "xxs" },
            regularPriceLabel,
            "\u00A0", regularPrice === null || regularPrice === void 0 ? void 0 :
            regularPrice.formatted,
            React.createElement(FootnoteReference, { refId: `fn_regular_price_stockcar_${carType}` }))),
        React.createElement(OptionsPrice, { vehicle: vehicle })));
};
