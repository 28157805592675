var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ACTIONS } from '@oneaudi/stck-store';
import { createCrsLogger } from './helpers';
import { getCachedValue, updateCachedValue } from './cache';
export function getCalculateRate(url, request, vehicleId, dispatch, trackingService, useCache = true) {
    return __awaiter(this, void 0, void 0, function* () {
        return getData(url, request, 'CalculateRate', vehicleId, dispatch, trackingService, useCache);
    });
}
export function getProducts(url, request, vehicleId, dispatch, trackingService, useCache = true) {
    return __awaiter(this, void 0, void 0, function* () {
        return getData(url, request, 'Products', vehicleId, dispatch, trackingService, useCache);
    });
}
export function getDefaultProduct(url, request, vehicleId, dispatch, trackingService, useCache = true) {
    return __awaiter(this, void 0, void 0, function* () {
        return getData(url, request, 'Defaults', vehicleId, dispatch, trackingService, useCache);
    });
}
function getData(url, request, requestedData, vehicleId, dispatch, trackingService, useCache) {
    return __awaiter(this, void 0, void 0, function* () {
        const crsLogger = createCrsLogger(vehicleId, requestedData);
        crsLogger.debug(`fetching data : useCache ${useCache}`);
        let result;
        if (useCache) {
            result = getCachedValue(crsLogger, vehicleId, requestedData);
        }
        if (!result) {
            const requestData = { Request: Object.assign(Object.assign({}, request), { '@Name': requestedData }) };
            try {
                result = yield crsRequest(crsLogger, trackingService, url, requestData);
                if (dispatch && vehicleId) {
                    dispatch(ACTIONS.FINANCING.addFinancingForVehicle({
                        vehicleId,
                        financing: {
                            financeRequestData: Object.assign({}, requestData),
                            financeReponseData: Object.assign({}, result),
                        },
                    }));
                }
                if (useCache) {
                    updateCachedValue(crsLogger, vehicleId, requestedData, result);
                }
            }
            catch (error) {
                if (dispatch && vehicleId) {
                    dispatch(ACTIONS.FINANCING.addFinancingForVehicle({
                        vehicleId,
                        financing: {
                            financeRequestData: undefined,
                            financeReponseData: undefined,
                        },
                    }));
                }
                const errorMsg = error.message ? error.message.replace('Error: ', '') : '';
                throw new Error(errorMsg);
            }
        }
        return result === null || result === void 0 ? void 0 : result.Response;
    });
}
export function crsRequest(crsLogger, trackingService, url, requestData) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        crsLogger.debug(`fetching data from server at ${url}`);
        const response = yield fetch(url, {
            body: JSON.stringify(requestData),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            method: 'POST',
        });
        const json = yield response.json();
        if (isErrorResponse(json)) {
            const errorDetails = (_a = json.Response) === null || _a === void 0 ? void 0 : _a.Error;
            crsLogger.warn('got error response from server', errorDetails.Description);
            trackingService === null || trackingService === void 0 ? void 0 : trackingService.track({
                event: {
                    eventInfo: {
                        eventAction: 'error',
                        eventName: 'vtp finance calculation error',
                    },
                    attributes: {
                        componentName: 'vtp-carInfo',
                        label: '',
                        targetUrl: '',
                        clickID: '',
                        elementName: '',
                        value: '',
                        pos: '',
                    },
                    error: {
                        name: errorDetails['@Class'],
                        codeInternal: errorDetails['@Code'],
                        type: errorDetails['@ErrorType'],
                        source: errorDetails['@Source'],
                        message: errorDetails.Description,
                    },
                },
            });
            throw new Error(errorDetails.Description);
        }
        crsLogger.debug('got data from server', json);
        return json;
    });
}
function isErrorResponse(responseData) {
    return !responseData || !responseData.Response || !!responseData.Response.Error;
}
